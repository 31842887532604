import React, { useState } from 'react';
import Header from '../auth/Header';
import Footer from '../auth/Footer';
import axios from '../../axios'
import { toast } from 'react-toastify';


const ContactSupport = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({});


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Clear error when user starts typing
        if (errors[e.target.name]) {
            setErrors({ ...errors, [e.target.name]: '' });
        }
    };
    const validateForm = () => {
        let newErrors = {};

        // Name validation
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        } else if (formData.name.trim().length < 2) {
            newErrors.name = 'Name must be at least 2 characters long';
        }

        // Email validation
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
        }

        // Subject validation
        if (!formData.subject.trim()) {
            newErrors.subject = 'Subject is required';
        } else if (formData.subject.trim().length < 5) {
            newErrors.subject = 'Subject must be at least 5 characters long';
        }

        // Message validation
        if (!formData.message.trim()) {
            newErrors.message = 'Message is required';
        } else if (formData.message.trim().length < 10) {
            newErrors.message = 'Message must be at least 10 characters long';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            fetchData()
        } else {
            console.log('Form has errors:', errors);
        }
    };


    const fetchData = () => {
        axios.post(`support/contactus`, { sEmail: formData.email, sName: formData.name, sSubject: formData.subject, sMessage: formData.message })
            .then((response) => {
                setFormData({ name: '', email: '', subject: '', message: '' });
                toast(
                    <div>
                        <div>Your query has been raised successfully.</div>
                    </div>
                    , { autoClose: 3000 });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <Header />
            <div className="report-probblem-sec cmn-banner-bg about-page-section ">
                <div className="change-psw-section reporting-box">
                    <div className='container'>
                        <div className="report-inner-box about-us-content contact-page-sec contact-support ">
                            <h3 className="text-center account-title page-title contact-title contact-support-title">Contact Us</h3>
                            <div className='row'>
                                <div className='col-xl-4'>
                                    <div className='contact-left-bar'>
                                        <div className='contact-info-sec'>
                                            <div className='contact-info-details'>
                                                <p>Feel free to reach out if you have any questions about rummy gameplay or any concerns. We offer customer support in India for registered users of the Rummy246 app.</p>
                                                <p>You can also send your queries or concerns via email or by calling our toll-free number.</p>
                                            </div>
                                            <div className='address-sec'>
                                                <h3>Corporate Office:</h3>
                                                <h6>Eseries Sports Private Ltd.</h6>
                                                <p>307-B, SCO 60-61-62, 3rd Floor,<br /> Sector 17C, Chandigarh G.P.O.,<br />Chandigarh - 160017.</p>
                                            </div>
                                            <div className='email-sec'>
                                                <ul>
                                                    <li><span>Email:</span><a href='mailto:support@rummy246.in'>support@rummy246.in</a></li>
                                                    <li><span>Toll-Free Number:</span><a href='tel:1800 890 2466'>1800 890 2466</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-8'>
                                    <div className="contactForm">
                                        <form onSubmit={handleSubmit}>
                                            <div className="formGroup">
                                                <label htmlFor="name">Name</label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                                {errors.name && <div className="error-message">{errors.name}</div>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="email">Email</label>
                                                <input
                                                    type="text"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <div className="error-message">{errors.email}</div>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="subject">Subject</label>
                                                <input
                                                    type="text"
                                                    id="subject"
                                                    name="subject"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                />
                                                {errors.subject && <div className="error-message">{errors.subject}</div>}
                                            </div>
                                            <div className="formGroup">
                                                <label htmlFor="message">Message</label>
                                                <textarea
                                                    id="message"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                ></textarea>
                                                {errors.message && <div className="error-message">{errors.message}</div>}
                                            </div>
                                            <button type="submit" className="submitButton">Submit</button>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='contact-info-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6'>

                            </div>
                            <div className='col-md-6'>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactSupport;