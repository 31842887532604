/* eslint-disable react-hooks/rules-of-hooks */
import { React, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { getTournamentTrasactions } from '../../../actions/tournamentTransactions'
import Loading from '../../../components/Loading'
import Paginate from '../../../components/Paginate';
import Select from 'react-select';


function tournamentTransaction() {

    const [tournamentTransactions, setTournamentTransactions] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [firstPage, setFirstPage] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const tournamentTrasactionsData = useSelector(state => state.tournamentTransactions.tournamentTransactionsData)

    useEffect(() => {
        document.title = "Rummy246 | My Account"
    }, [])

    useEffect(() => {
        dispatch(getTournamentTrasactions(pageSize, currentPage, token))
        setLoading(true)
    }, [currentPage, pageSize])



    useEffect(() => {
        if (tournamentTrasactionsData?.transactions?.length) {
            setLoading(false)
            setTournamentTransactions(tournamentTrasactionsData?.transactions)
            setPageCount(Math.ceil((tournamentTrasactionsData?.count?.totalData) / pageSize));
            setTotalData(tournamentTrasactionsData?.count?.totalData)
            setLoading(false)
        }
        else if (tournamentTrasactionsData) {
            setLoading(false)
            setTournamentTransactions(null)
        }
    }, [tournamentTrasactionsData])



    function dateFormat(date) {
        return moment(date).format("MMM DD, yyyy, hh:mm A")
    }

    const handlePageClick = async (data) => {
        setCurrentPage(data.selected)
    }

    function handlePageSize(e) {
        if (Number(e.value) > 10) {
            setCurrentPage(0)
            setFirstPage(true)
        }
        setPageSize(Number(e.value))
        setPageCount(Math.ceil(totalData / e.value))
    }

    const options = [{ value: '10', label: 'Show 10' }, { value: '25', label: 'Show 25' }, { value: '40', label: 'Show 40' }]

    return (
        <>
            {loading && <Loading />}
            <div className="account-cnt">
                <div className="game-transaction-section tournament-transection-wrap">
                    <h3 className="text-center account-title">Tournament Transactions</h3>
                    {tournamentTransactions?.length ?
                        <>
                            <div className="table-cnt table-cnt-box br-6">
                                <div className="table-responsive">
                                    <table className="common-table table">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Tournament ID</th>
                                                <th className="text-center">Round</th>
                                                <th className="text-center">Position</th>
                                                <th className="text-center">Amount Debited</th>
                                                <th className="text-center">Amount Credited</th>
                                                <th className="text-center">Date & Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tournamentTransactions.map((tournamentTransaction, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">
                                                        {tournamentTransaction?.iTournamentId || '0'}
                                                    </td>
                                                    <td className="text-center">
                                                        {tournamentTransaction?.nRound || '0'}
                                                    </td>
                                                    <td className="text-center">
                                                        {tournamentTransaction?.nPosition || '0'}
                                                    </td>
                                                    <td className="text-center">
                                                        &#8377; {tournamentTransaction?.nDebit ? tournamentTransaction?.nDebit?.toFixed(2) : '0'}
                                                    </td>
                                                    <td className="text-center">
                                                        &#8377; {tournamentTransaction?.nCredit === '-' ? '0' : tournamentTransaction?.nCredit?.toFixed(2)}
                                                    </td>
                                                    <td className="text-center">
                                                        {tournamentTransaction.dCreatedDate ? dateFormat(tournamentTransaction.dCreatedDate) : "Not Available"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="transaction-btn-wrap d-flex align-items-center">
                                <div className="cust-dropdown-wrap">
                                    <div className="cust-dropdown-box text-center">
                                        <Select
                                            value={options.filter(function (option) {
                                                return option.value == pageSize;
                                            })}
                                            onChange={e => handlePageSize(e)}
                                            options={options}
                                            className="dropdown-blue-box"
                                            classNamePrefix={'inner-dropdown'}
                                            menuPlacement={'auto'}
                                        />
                                    </div>
                                </div>
                                <div className="pagination-wrap text-center">
                                    <div>
                                        <Paginate pageCount={pageCount} handlePageClick={handlePageClick} firstPage={firstPage} />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="pagination-section d-flex justify-content-between">
                                    <Paginate pageCount={pageCount} handlePageClick={handlePageClick} firstPage={firstPage} />
                                    <select
                                        value={pageSize}
                                        onChange={e => handlePageSize(e)}>
                                        {[10, 25, 40].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                        </>
                        : <div className="table-cnt table-cnt-box br-6">
                            <div className="table-responsive">
                                <table className="common-table table">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Tournament ID</th>
                                            <th className="text-center">Round</th>
                                            <th className="text-center">Position</th>
                                            <th className="text-center">Amount Debited</th>
                                            <th className="text-center">Amount Credited</th>
                                            <th className="text-center">Date & Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                Not Available
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                </div>
            </div>
        </>
    );
}

export default connect()(tournamentTransaction);
