import { React, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import Loading from '../../../components/Loading'
import { referredBonus } from '../../../actions/bonus'
import Paginate from '../../../components/Paginate';
import Select from 'react-select';


function Bonus() {

    const [loading, setLoading] = useState(false);
    const [bafBonusData, setBafBonusData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [firstPage, setFirstPage] = useState(false);


    const dispatch = useDispatch()

    const token = useSelector(state => state.auth.token)
    const referredBonusData = useSelector(state => state.bonus.data)

    useEffect(() => {
        document.title = "Rummy246 | My Account"
        setLoading(true)
        dispatch(referredBonus(pageSize, currentPage, token))
    }, [currentPage, pageSize])

    useEffect(() => {
        if (referredBonusData) {
            setLoading(false)
            setBafBonusData(referredBonusData)
            setPageCount(Math.ceil((referredBonusData?.length) / pageSize));
            setTotalData(referredBonusData?.length / 2)

        }
    }, [referredBonusData]);

    const handlePageClick = async (data) => {
        setCurrentPage(data.selected)
    }


    function handlePageSize(e) {
        if (Number(e.target.value) > 10) {
            setCurrentPage(0)
            setFirstPage(true)
        }
        setPageSize(Number(e.target.value))
        setPageCount(Math.ceil(totalData / e.target.value))
    }

    const options = [{ value: '10', label: 'Show 10' }, { value: '25', label: 'Show 25' }, { value: '40', label: 'Show 40' }]

    return (
        <>
            {loading && <Loading />}
            <div className="table-heading lobby-headeing">Bonus</div>
            <div className="account-cnt">
                <div className="game-transaction-section bonus-transaction-section">

                    <div className="game-transaction-table passbook-wrap">
                        {bafBonusData?.length ?
                            <>
                                <div className="table-cnt table-cnt-box br-6">
                                    <div className="table-responsive">
                                        <table className="common-table table">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" width="25%">Friend&apos;s Name</th>
                                                    <th className="text-center" width="25%">Pending Bonus</th>
                                                    <th className="text-center" width="25%">Released Bonus</th>
                                                    <th className="text-center" width="25%">Total Bonus</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bafBonusData.map((baf, i) => (
                                                    <tr className="text-center" key={i}>
                                                        <td className="text-center">
                                                            {baf?.oReferredUser?.sUserName || "-"}
                                                        </td>

                                                        <td className="text-center">
                                                            &#8377;{baf?.nBonus}
                                                        </td>
                                                        <td className="text-center">
                                                            &#8377;{baf?.nUsed}
                                                        </td>
                                                        <td className="text-center">
                                                            &#8377;{baf?.nBonus + baf?.nUsed}
                                                        </td>
                                                    </tr>
                                                ))}
                                                {/* <tr>
                                        <td>
                                            Hiten Dodiya
                                        </td>
                                        <td className="text-center">
                                            &#8377;  1000
                                        </td>
                                        <td className="text-center">
                                            &#8377;  500
                                        </td>
                                        <td className="text-center">
                                            &#8377;  1500
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Dharan Doshi
                                        </td>
                                        <td className="text-center">
                                            &#8377;  1000
                                        </td>
                                        <td className="text-center">
                                            &#8377;  500
                                        </td>
                                        <td className="text-center">
                                            &#8377;  1500
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Hitesh Dabhi
                                        </td>
                                        <td className="text-center">
                                            &#8377;  1000
                                        </td>
                                        <td className="text-center">
                                            &#8377;  500
                                        </td>
                                        <td className="text-center">
                                            &#8377;  1500
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Hiten Dodiya
                                        </td>
                                        <td className="text-center">
                                            &#8377;  1000
                                        </td>
                                        <td className="text-center">
                                            &#8377;  500
                                        </td>
                                        <td className="text-center">
                                            &#8377;  1500
                                        </td>
                                    </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="transaction-btn-wrap d-flex align-items-center">
                                    <div className="cust-dropdown-wrap">
                                        <div className="cust-dropdown-box text-center">
                                            <Select
                                                value={options.filter(function (option) {
                                                    return option.value == pageSize;
                                                })}
                                                onChange={e => handlePageSize(e)}
                                                options={options}
                                                className="dropdown-blue-box"
                                                classNamePrefix={'inner-dropdown'}
                                                menuPlacement={'auto'}
                                            />
                                        </div>
                                    </div>
                                    <div className="pagination-wrap text-center">
                                        <div>
                                            <Paginate pageCount={pageCount} handlePageClick={handlePageClick} firstPage={firstPage} />
                                        </div>
                                    </div>
                                </div>
                            </> :
                            <div className="table-cnt table-cnt-box br-6">
                                <div className="table-responsive">
                                    <table className="common-table table">
                                        <thead>
                                            <tr>
                                                <th className="text-center" width="25%">Friend&apos;s Name</th>
                                                <th className="text-center" width="25%">Pending Bonus</th>
                                                <th className="text-center" width="25%">Released Bonus</th>
                                                <th className="text-center" width="25%">Total Bonus</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="6" className="text-center">
                                                    You have not invited any friends yet!
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default connect()(Bonus);
