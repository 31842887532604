import React from 'react';
import Footer from '../auth/Footer'
import Header from '../auth/Header';
const TermsAndConditions = () => {
    return (
        <>
            <Header />

                 <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                    <div className="container">
                        <div className="about-us-content desclaimer">
                            <h3 className="text-center page-title text-2xl font-bold mb-4">Terms Of Condition</h3>
                            <div className='about-details-sec'>
                                <h3 className="text-xl font-bold mt-4 mb-2">Important Notification</h3>
                                <h4 className="text-lg font-semibold mt-3 mb-1">State Restrictions and User Eligibility</h4>
                                <p>Please be aware that the laws in Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim, and Telangana restrict players from using the services offered on our website. Players from these states may face penalties imposed by the State Government or Central Government of India.</p>
                                <p>At Rummy 246, we do not accept registration requests from individuals residing in these restricted states. If a player from these states creates an account by providing false information (such as incorrect state details or IP addresses), the responsibility and consequences will solely lie with the player. We reserve the right to terminate such accounts without prior notice and may confiscate any deposits and/or winnings associated with these accounts.</p>

                                <h3 className="text-xl font-bold mt-4 mb-2">Definition of Services</h3>
                                <p>The term 'Services' refers to all products and services provided to you, the player, by Rummy 246 and/or its affiliates, subsidiaries, associates, and partners through our skill gaming website, Rummy 246. The use of our website and services will be governed by these Terms of Service, referred to hereafter as 'Terms.'</p>

                                <h3 className="text-xl font-bold mt-4 mb-2">Acceptance of Terms</h3>
                                <p>It is mandatory and binding for you to read these Terms in their entirety before using our services. Any term found to be unlawful, invalid, void, voidable, or unenforceable by any judicial or quasi-judicial body in India will not affect the validity and enforceability of the remaining Terms. Such terms will be replaced with valid and enforceable terms that closely align with the original intent.</p>

                                <h3 className="text-xl font-bold mt-4 mb-2">Acknowledgment of Terms</h3>
                                <p>By registering on our website, you acknowledge that you have understood and accepted these Terms. We recommend printing all transaction records, rules of play, cancellation policies, and payout policies for your reference.</p>

                                <h3 className="text-xl font-bold mt-4 mb-2">Rights and Waivers</h3>
                                <p>A delay in taking action or exercising rights in response to any breach of these Terms does not constitute a waiver of our rights or invalidate these Terms for future breaches. If you do not accept any part of these Terms or any future modifications, we recommend that you discontinue using our website and services.</p>

                                <h3 className="text-xl font-bold mt-4 mb-2">Account Creation: User Account, Password, and Security</h3>
                                <p>To access and utilize the services on Rummy 246, it is essential to complete the registration process. By registering, you agree to provide accurate and up-to-date information as required by the website.</p>

                                <h4 className="text-lg font-semibold mt-3 mb-1">Registration Process</h4>
                                <ul className="list-disc pl-5 mb-4">
                                    <li><strong>Initiating Registration:</strong> Go to the homepage of Rummy 246 and either use the quick registration form or click on the "Register For Free" button to begin the registration process. Your registration confirms your acceptance of these Terms.</li>
                                    <li><strong>Creating a Username and Password:</strong> During registration, you will need to create a unique Username/Login Name and a secure password. You must also provide a valid email address. Your Username should not be vulgar, racist, blasphemous, or offensive, and it must not infringe on any trademark or proprietary rights of third parties.</li>
                                    <li><strong>Account Security:</strong> Your account is personal and confidential. You are responsible for maintaining the confidentiality of your Username and password and for all activities that occur under your account. Do not share your account details or allow anyone else to use your account. If you suspect unauthorized use or theft of your account, you must immediately notify Rummy 246 and provide evidence of such incidents if requested.</li>
                                    <li><strong>Liability:</strong> Rummy 246 will not be held liable for any loss incurred due to unauthorized use or theft of your account. It is your responsibility to ensure the security of your account information.</li>
                                    <li><strong>Verification and Compliance:</strong> The website may require you to verify the accuracy of the information provided during registration and to provide additional contact information as needed. Rummy 246 reserves the right to cancel, suspend, or terminate your account if you violate any of these Terms.</li>
                                    <li><strong>Sensitive Information:</strong> Do not distribute or upload your Username, login details, or other sensitive registration information on the web.</li>
                                    <li><strong>Conduct:</strong> You are expected to treat other players with respect and courtesy. Any abusive, vulgar, racist, or offensive behavior may result in the cancellation, suspension, or termination of your account.</li>
                                    <li><strong>Indemnification:</strong> You agree not to hold Rummy 246 liable for any claims, demands, damages, or losses, including reasonable attorneys' fees, asserted by third parties due to your use of the services or due to the use of the services by third parties using your account.</li>
                                </ul>

                                <h3 className="text-xl font-bold mt-4 mb-2">Account Management and Validation</h3>
                                <h4 className="text-lg font-semibold mt-3 mb-1">Account Management</h4>
                                <p>If your account remains inactive (i.e., not accessed) for a period exceeding one year, Rummy 246 will issue a notification prompting you to either log in and continue using our services or confirm account closure. If you do not respond within the specified timeframe, Rummy 246 reserves the right to close the account and forfeit any remaining balances. This policy is designed to maintain account security and manage resources effectively.</p>

                                <h4 className="text-lg font-semibold mt-3 mb-1">Account Validation</h4>
                                <ul className="list-disc pl-5 mb-4">
                                    <li><strong>Validation Process:</strong> Rummy 246 may validate your account through email or phone as necessary. All players must register with a valid email ID and mobile number to receive promotional offers, campaign mailers, SMSs, and push notifications from Boom Games Pvt. Ltd.</li>
                                    <li><strong>Authorization:</strong> By agreeing to our Terms and Conditions, you authorize us to send promotional and campaign offers via mailers, SMSs, and push notifications to your registered mobile number. This authorization includes calls related to promotional offers, regardless of your status on the National Do Not Call Registry.</li>
                                    <li><strong>Opt-Out:</strong> If you prefer not to receive promotional mailers or SMSs, you can opt out by emailing us at support@rummy246.in with your user details, including your username, registered email, and mobile number. Your email ID and mobile number will be kept confidential and used exclusively for internal purposes.</li>
                                    <li><strong>Unsuccessful Validation:</strong> In cases where validation is unsuccessful, Rummy 246 reserves the right to prevent players from logging into their accounts.</li>
                                    <li><strong>Proof of Identification:</strong> The website may request proof of identification at any time. You must comply with such requests. Rest assured that any information provided will be kept confidential and used solely for verifying the authenticity of the account.</li>
                                </ul>

                                {/* Continue adding the remaining sections here */}

                                <h3 className="text-xl font-bold mt-4 mb-2">Player Representations</h3>
                                <h4 className="text-lg font-semibold mt-3 mb-1">Account Termination</h4>
                                <ul className="list-disc pl-5 mb-4">
                                    <li>If you wish to discontinue using Rummy 246 and its Services, please email us at support@Rummy246.com to request the termination of your account.</li>
                                    <li>Upon account closure, we will process any positive balance in your account using a method determined by us. We reserve the right to collect any fees, surcharges, or costs incurred before the cancellation.</li>
                                </ul>

                                <h4 className="text-lg font-semibold mt-3 mb-1">Breach of Terms</h4>
                                <ul className="list-disc pl-5 mb-4">
                                    <li>In the event of a breach of these Terms, we reserve the right to suspend, terminate, or discontinue your account without prior notice.</li>
                                    <li>You agree that any breach of the Terms may result in the forfeiture of any money in your account. We also reserve the right to initiate legal proceedings against you, as per applicable laws.</li>
                                </ul>

                                <h4 className="text-lg font-semibold mt-3 mb-1">Account Misuse</h4>
                                <ul className="list-disc pl-5 mb-4">
                                    <li>Your account is personal and must be used exclusively by you. If we find that you have created an account to allow someone else to play or have provided access to another person, we reserve the right to bar you from using Rummy 246.</li>
                                    <li>Providing false details during registration, impersonating another individual, or unauthorized use of the Services may lead to being barred from Rummy 246 and its Services. We may also initiate legal proceedings, whether civil or criminal, as applicable.</li>
                                </ul>

                                <h4 className="text-lg font-semibold mt-3 mb-1">Reasons for Account Suspension or Termination</h4>
                                <p>We may suspend or terminate your account and freeze or forfeit the money therein for reasons including but not limited to:</p>
                                <ul className="list-disc pl-5 mb-4">
                                    <li>Suspected breach of account security or unauthorized access.</li>
                                    <li>Use of the account for unlawful or fraudulent purposes, including money laundering.</li>
                                    <li>Charge-backs or disputes on your account.</li>
                                    <li>Involvement in team building, cheating, or collusion during games or tournaments.</li>
                                    <li>Being a minor or under the age of 18.</li>
                                    <li>The provision of our Service is illegal in the jurisdiction from where you are accessing it.</li>
                                    <li>Providing incorrect or deceptive information, or concealing relevant information about your address or residence.</li>
                                </ul>

                                <h4 className="text-lg font-semibold mt-3 mb-1">Fraudulent Activities</h4>
                                <p>Fraudulent activities include but are not limited to:</p>
                                <ul className="list-disc pl-5 mb-4">
                                    <li>Using payment instruments (credit cards, debit cards, net-banking credentials, etc.) not belonging to you or without the owner's permission.</li>
                                    <li>Impersonating another person.</li>
                                    <li>Unauthorized access and use of another user's account.</li>
                                </ul>

                                <h4 className="text-lg font-semibold mt-3 mb-1">Account Termination Due to Fraud</h4>
                                <p>If your account is terminated due to a breach of Terms, we may freeze the balance in your account until the legal process for fraud mitigation is complete. This will be in accordance with the legal procedures in force.</p>

                                <h4 className="text-lg font-semibold mt-3 mb-1">Discretionary Rights</h4>
                                <p>We reserve the right to suspend or close an account at our sole discretion during the investigation of any legal or regulatory obligations. We may also continue suspension or opt to close the account at our discretion after legal proceedings are completed.</p>

                            </div>
                        </div>
                    </div>
                </section>
             <Footer />
        </>
    );
};

export default TermsAndConditions;