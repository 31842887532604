import { React, useState, useEffect } from 'react';
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux'
import { getGameTrasactions } from '../../../actions/gameTrasactions'
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading'
import Paginate from '../../../components/Paginate';
import Select from 'react-select';


function GameTransaction() {

    const [gameTransactions, setGameTransactions] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [firstPage, setFirstPage] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const gameTrasactionsData = useSelector(state => state.gameTrasactions.gameTrasactionsData)


    useEffect(() => {
        document.title = "Rummy246 | My Account"
    }, [])

    useEffect(() => {
        dispatch(getGameTrasactions(pageSize, currentPage, token))
        setLoading(true)
    }, [currentPage, pageSize])

    useEffect(() => {
        if (gameTrasactionsData?.transactions?.length) {
            setGameTransactions(gameTrasactionsData?.transactions)
            setPageCount(Math.ceil((gameTrasactionsData?.count[0]?.totalData) / pageSize));
            setTotalData(gameTrasactionsData?.count[0]?.totalData)
            setLoading(false)
        }
        else if (gameTrasactionsData) {
            setLoading(false)
        }
    }, [gameTrasactionsData])


    function dateFormat(date) {
        return moment(date).format("MMM DD yyyy hh:mm A")
    }

    const handlePageClick = async (data) => {
        setCurrentPage(data.selected)
    }


    function handlePageSize(e) {
        if (Number(e.value) > 10) {
            setCurrentPage(0)
            setFirstPage(true)
        }
        setPageSize(Number(e.value))
        setPageCount(Math.ceil(totalData / e.value))
    }

    const options = [{ value: '10', label: 'Show 10' }, { value: '25', label: 'Show 25' }, { value: '40', label: 'Show 40' }]
    return (
        <>
            {loading && <Loading />}
            <div className="table-heading lobby-headeing">Game Transactions</div>
            <div className="account-cnt">
                <div className="game-transaction-section">

                    {gameTransactions?.length ?
                        <>
                            <div className='game-transaction-table'>
                                <div className="table-cnt table-cnt-box br-6">
                                    <div className="table-responsive">
                                        <table className="common-table table">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" width="20%">Date & Time</th>
                                                    <th className="text-center" width="20%">Description</th>
                                                    <th className="text-center" width="15%">Amount Credited</th>
                                                    <th className="text-center" width="15%">Bonus Credited</th>
                                                    <th className="text-center" width="15%">Amount Debited</th>
                                                    <th className="text-center" width="15%">Bonus Debited</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {gameTransactions.map((gameTransaction, i) => (
                                                    <tr key={i}>
                                                        <td className="text-center">
                                                            {gameTransaction?.dCreatedDate ? dateFormat(gameTransaction?.dCreatedDate) : "Not Available"}
                                                        </td>
                                                        <td className="text-center">
                                                            <Link className="common-link" to={`/game-transaction/table-transaction/${gameTransaction?.iTableId}`}>
                                                                {gameTransaction?.sDescription === "point21 2 player" ? "Point(21 Cards) 2 player" : gameTransaction?.sDescription === "point21 6 player" ? "Point(21 Cards) 6 player" : gameTransaction?.sDescription}
                                                            </Link>
                                                        </td>
                                                        <td className="text-center">
                                                            &#8377; {gameTransaction?.nAmountIn?.toFixed(2) || 0}
                                                        </td>
                                                        <td className="text-center">
                                                            &#8377;{gameTransaction?.nBonusIn?.toFixed(2) || '0'}
                                                        </td>
                                                        <td className="text-center">
                                                            &#8377; {gameTransaction?.nAmountOut?.toFixed(2) || '0'}
                                                        </td>
                                                        <td className="text-center">
                                                            &#8377;{gameTransaction?.nBonusOut?.toFixed(2) || '0'}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="transaction-btn-wrap d-flex align-items-center">
                                    <div className="cust-dropdown-wrap">
                                        <div className="cust-dropdown-box text-center">
                                            <Select
                                                // defaultValue={pageSize}
                                                value={options.filter(function (option) {
                                                    return option.value == pageSize;
                                                })}
                                                onChange={e => handlePageSize(e)}
                                                options={options}
                                                className="dropdown-blue-box"
                                                classNamePrefix={'inner-dropdown'}
                                                menuPlacement={'auto'}
                                            />
                                            {/* <select
                                                value={pageSize}
                                                onChange={e => handlePageSize(e)}>
                                                {[10, 25, 40].map(pageSize => (
                                                    <option key={pageSize} value={pageSize}>
                                                        Show {pageSize}
                                                    </option>
                                                ))}
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className="pagination-wrap text-center">
                                        <div>
                                            <Paginate pageCount={pageCount} handlePageClick={handlePageClick} firstPage={firstPage} />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="pagination-section d-flex justify-content-between">
                                    <Paginate pageCount={pageCount} handlePageClick={handlePageClick} firstPage={firstPage} />
                                    <select
                                        value={pageSize}
                                        onChange={e => handlePageSize(e)}>
                                        {[10, 25, 40].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                            </div>
                        </>
                        :
                        <div className="table-cnt table-cnt-box br-6">
                            <div className="table-responsive">
                                <table className="common-table table">
                                    <thead>
                                        <tr>
                                            <th className="text-center" width="20%">Date & Time</th>
                                            <th className="text-center" width="20%">Description</th>
                                            <th className="text-center" width="15%">Amount Credited</th>
                                            <th className="text-center" width="15%">Bonus Credited</th>
                                            <th className="text-center" width="15%">Amount Debited</th>
                                            <th className="text-center" width="15%">Bonus Debited</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                Not Available
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                </div>
            </div>
        </>
    );
}

export default connect()(GameTransaction);
