import React from 'react'
import Footer from '../auth/Footer'
import Header from '../auth/Header';

const AboutUs = () => {
    return (
        <div>
            <Header />
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content">
                        <h3 className="text-center page-title">About Us</h3>
                        <div className='about-details-sec'>
                            <h2 className="text-2xl font-bold mt-4 mb-2">Welcome to Rummy 246!</h2>
                            <p>Rummy 246 is your premier destination for online rummy gaming, offering a complete experience of entertainment and excitement. As one of the safest and most trusted platforms in the industry, we hold our users in the highest regard. Our journey began on [Launch Date], and since then, our community of players has continued to grow and thrive.</p>

                            <h3 className="text-xl font-bold mt-4 mb-2">Why Choose Rummy 246?</h3>
                            <p>At Rummy 246, player satisfaction is our top priority. We are committed to delivering a gaming experience that meets your expectations and desires. Our campaigns and tournaments are designed with your interests in mind, aiming to provide opportunities to "play more and win more."</p>

                            <h3 className="text-xl font-bold mt-4 mb-2">What We Offer</h3>
                            <p>Rummy 246 caters to a wide range of gaming preferences with a variety of rummy formats, including:</p>
                            <ul className="list-disc pl-5 mb-4">
                                <li><strong>10 Card Rummy</strong></li>
                                <li><strong>13 Card Rummy</strong></li>
                                <li><strong>21 Card Rummy</strong></li>
                                <li><strong>27 Card Rummy</strong></li>
                            </ul>
                            <p>We offer these games in different variants, such as Point, Deal, and Pool, each providing unique challenges and rewards. Our platform is designed to offer endless opportunities to showcase your skills, win amazing prizes, and enjoy fast withdrawals.</p>

                            <h3 className="text-xl font-bold mt-4 mb-2">Safety, Security, and Transparency</h3>
                            <p>Rummy 246 prioritizes your safety and security. We use a Random Number Generator (RNG) for card shuffling, ensuring fairness and randomness in every game. In addition to RNG, we uphold the principles of responsible gaming to keep the experience enjoyable for everyone. Our platform employs the highest standards of online security to protect all transactions and personal information.</p>

                            <p>Join us at Rummy 246, where gaming meets excellence. Experience the thrill, enjoy the rewards, and be part of a community that values fun and fairness. Welcome to a new era of online rummy gaming!</p>

                            <h3 className="text-xl font-bold mt-4 mb-2">About Rummy246.in</h3>
                            <p>Rummy246.in is a one-stop destination for all rummy lovers. The platform is packed with cash prizes, tournaments, and amazing entertainment.</p>
                            <p>We are a young team that believes in the passion of gaming and how it shapes us. Considering the growth of the card game industry in the last decade, we launched our very own Rummy platform known as the Rummy246.in</p>
                            <p>Our platform comprises all the features that provide the ultimate gaming experience. Every Rummy game on Rummy246.in is packed with excitement and surprises. The gaming modes of each rummy variant welcome every player and encourages them to build and improve their skills. So, whether you choose points in the 13 card rummy variant, we have an interesting gaming session waiting for you.</p>
                            <p>We understand the concerns and expectations of our customers when they register themselves on our platform and, therefore, have SSL protocols that ensure fair gameplay and equal opportunities among them. Features like 24/7 assistance, KYC processes for players, and complete verification make Rummy246.in one of the safest and best platforms to play Rummy.</p>
                            <p>Our players can choose to play for free or for cash prizes. So, don't wait and register yourself at Rummy246.in to gain rewards and a thrilling experience.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default AboutUs