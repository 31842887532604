import React from 'react'
// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';


const TDS = () => {
    return (
        <div>
            {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****--> */}
            {/* <!--***** Middle Section *****--> */}
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <h3 className="text-center page-title">TDS Policy</h3>
                        <div className='about-details-sec'>
                            <p>In the Union Budget 2023, the Government of India introduced a Finance Bill that sets forth new tax regulations for online gaming, effective from the financial year 2023-24. According to these regulations, it is mandatory for online gaming platforms to deduct Tax at Source (TDS) whenever a player makes a withdrawal, as well as at the end of the financial year. TDS will be imposed at a rate of 30% on all 'Net Winnings' of the player.</p>

                            <h5>What is TDS?</h5>
                            <p>TDS stands for Tax Deducted at Source. It is a tax collected at the point of origin of income. For online gaming, a 30% tax is deducted only on the 'Net Winnings' whenever a player requests a withdrawal.</p>
                            <p>Under the income tax laws, any individual or organization that is required to make certain types of payments to another party must deduct tax at source and then remit the deducted amount to the Central Government. The entity responsible for deducting the tax will also issue a TDS certificate to the payer.</p>

                            <h5>What are Net Winnings?</h5>
                            <p>Net Winnings are calculated as the difference between the total amount a player has withdrawn and the total deposits they have made. Essentially, Net Winnings represent the actual profit a player has earned from their gaming activities.</p>

                        </div>
                    </div>
                </div>
            </section>



            {/* <!--***** Footer *****--> */}
            <Footer />
        </div>
    )
}

export default TDS