import React from 'react'
import Footer from '../auth/Footer'
import Header from '../auth/Header';

const Legality = () => {
    return (
        <div>
            <Header />
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <h3 className="text-center page-title">Legality</h3>
                        <div className='about-details-sec'>
                            <h3>Is Online Rummy Legal in India?</h3>
                            <p>The straightforward answer is yes—playing rummy online is legal in India.</p>

                            <h3>Company Information:</h3>
                            <p>Rummy 246 is operated by ESERIES SPORTS PRIVATE LIMITED, a company incorporated under the Companies Act 1956, with its registered office at CN-307, SCO 60-61-62, 3RD FLOOR, SECTOR 17C, Chandigarh G.P.O., Chandigarh.</p>

                            <h3>Legal Framework:</h3>
                            <p>Gaming legislations across various states in India have different stances on online gaming. However, there is a significant precedent set by the Indian judiciary regarding games of skill.</p>
                            <p>In 1967, the Supreme Court of India ruled that rummy is a game of skill, not gambling. This landmark judgment established that games which require substantial skill and strategy, such as rummy, do not fall under the category of gambling. The court's ruling emphasized that the preponderance of skill over chance in rummy makes it a permissible game under Indian law.</p>
                            <p>Over the years, this ruling has been reinforced by various High Courts and Supreme Court decisions. Notably, a three-judge bench of the Supreme Court in 1996 upheld the earlier judgment, clarifying that games involving a significant degree of skill, including rummy, are not considered gambling. The Court also differentiated between games of skill and those of chance, stating that only the latter fall within the gambling definition.</p>
                            <p>In 2015, the Supreme Court further clarified its stance by dismissing a 2012 Madras High Court order that deemed rummy for stakes as illegal. The Court recognized that such judgments did not hold and reaffirmed that playing skill-based games for stakes does not constitute gambling.</p>
                            <p>According to the Constitution of India, offering games of skill is considered a fundamental right. Consequently, online rummy, which involves skillful play, is legal and cannot be prohibited based on its skill-based nature.</p>

                            <h3>State-Specific Regulations:</h3>
                            <p>While online rummy is generally legal, specific states have their regulations:</p>
                            <ul>
                                <li><strong>Andhra Pradesh</strong>: The state has enacted laws that prohibit online gaming, including rummy, under certain conditions.</li>
                                <li><strong>Assam</strong>: Gaming laws in Assam are restrictive and currently prohibit online rummy.</li>
                                <li><strong>Nagaland</strong>: The state's gaming regulations do not allow online rummy.</li>
                                <li><strong>Odisha</strong>: Online rummy is restricted under current gaming regulations.</li>
                                <li><strong>Sikkim</strong>: The state's gaming regulations do not permit online rummy.</li>
                                <li><strong>Telangana</strong>: Online rummy is not permitted under Telangana's gaming laws.</li>
                            </ul>
                            <p>As a result, residents of Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim, and Telangana are not permitted to participate in real money rummy games on Rummy 246.</p>

                            <h3>Compliance:</h3>
                            <p>ESERIES SPORTS PRIVATE LIMITED is committed to complying with all applicable laws and regulations governing online games of skill. We continuously monitor legal developments and ensure adherence to any changes in the legal framework imposed by courts, regulatory bodies, central or state governments, or law enforcement agencies.</p>

                            <p>For further information or clarification regarding the legality of playing on Rummy 246, please contact us at support@rummy246.in.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Legality