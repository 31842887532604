/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/rules-of-hooks */
import { React, useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { changePasswordRequest } from '../../../actions/changePassword';
import { verifyLength, verifyPassword } from '../../../helper';
import Loading from '../../../components/Loading';
import { logoutDuetoPasswordChange } from '../../../actions/auth';

function changePassword() {

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [disableReset, setDisableReset] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showPass1, setShowPass1] = useState(false);
    const [showPass2, setShowPass2] = useState(false);
    const [showPass3, setShowPass3] = useState(false);

console.log('disableReset', disableReset)
    const [errCurrentPassword, setErrCurrentPassword] = useState('');
    const [errNewPassword, setErrNewPassword] = useState('');
    const [errConfirmNewPassword, setErrConfirmNewPassword] = useState('');

    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const resMessage = useSelector(state => state.changePassword.resMessage)
    const resStatus = useSelector(state => state.changePassword.resStatus)

    const previousProps = useRef({ resMessage, resStatus }).current

    useEffect(() => {
        if (previousProps.resMessage !== resMessage) {
            if (resMessage?.length) {
                toast(
                    <div>
                        <div>
                            {resMessage === "Password changed successfully." ? "Password changed successfully, Logging you out." : resMessage}
                        </div>
                    </div>
                );
            }
        }
        return () => {
            previousProps.resMessage = resMessage
        }
    }, [resMessage])

    useEffect(() => {
        document.title = "Rummy246 | My Account"
    }, [])

    useEffect(() => {
        if (previousProps.resStatus !== resStatus) {
            if (resStatus === true) {
                localStorage.removeItem('Token')
                setLoading(false)
                setCurrentPassword('')
                setNewPassword('')
                setConfirmNewPassword('')
            }
            if (resStatus === false) {
                setLoading(false)
                setNewPassword('')
                setConfirmNewPassword('')
            }
        }
        return () => {
            previousProps.resStatus = resStatus
        }
    }, [resStatus])

    useEffect(() => {
        if (previousProps.resStatus !== resStatus) {
            if (resStatus === true) {
                const timer = setTimeout(() => {
                    dispatch(logoutDuetoPasswordChange())
                }, 2000);
                return () => clearTimeout(timer);
            }
        }
        return () => {
            previousProps.resStatus = resStatus
        }
    }, [resStatus])

    function handlePassword(e, type) {
        
        switch (type) {
            case 'current':

                if (!verifyPassword(e.target.value)) {
                    setErrCurrentPassword('It must contain minimum 6 characters and maximum 12 characters.')
                } else if (!verifyLength(e.target.value, 1)) {
                    setErrCurrentPassword('Enter your current password')
                }else if(!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/.test(e.target.value)){
                    setErrCurrentPassword( "Password need to be 8 to 16 characters")
                }else{
                    setErrCurrentPassword('')
                }

                if (newPassword?.length && e.target.value == newPassword) {
                    setErrNewPassword("Current password and new password can't be same")
                } else {
                    setErrNewPassword('')
                }
                setCurrentPassword(e.target.value)
                break
            case 'new':
                if (!verifyPassword(e.target.value)) {
                    setErrNewPassword('It must contain minimum 6 characters and maximum 12 characters.')
                } else if (!verifyLength(e.target.value, 1)) {
                    setErrNewPassword('Enter new password')
                }else if(!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/.test(e.target.value)){
                    setErrNewPassword( "Password need to be 8 to 16 characters")
                }else {
                    setErrNewPassword('')
                }
                if (currentPassword == e.target.value) {
                    setErrNewPassword("Current password and new password can't be same")
                }

                if (confirmNewPassword?.length && e.target.value != confirmNewPassword) {
                    setErrConfirmNewPassword("Passwords don't match")
                } else {
                    setErrConfirmNewPassword("")
                }
                setNewPassword(e.target.value)
                break
            case 'confirmNew':
                if (!verifyPassword(e.target.value)) {
                    setErrConfirmNewPassword('It must contain minimum 6 characters and maximum 12 characters.')
                } else if (!verifyLength(e.target.value, 1)) {
                    setErrConfirmNewPassword('Enter new password again')
                }
                else if (newPassword != e.target.value) {
                    setErrConfirmNewPassword("Passwords don't match")
                }
                setConfirmNewPassword(e.target.value)
                break
        }
    }

    useEffect(() => {
        if (currentPassword?.length && newPassword?.length && confirmNewPassword?.length && !errCurrentPassword && !errNewPassword && !errConfirmNewPassword) {
            setDisableSubmit(true)
        } else {
            setDisableSubmit(false)
        }
        if(currentPassword || newPassword || confirmNewPassword){
            setDisableReset(false)
        }else{
            setDisableReset(true)
        }
    }, [currentPassword, newPassword, confirmNewPassword])

function handleReset(){
    setCurrentPassword('')
    setNewPassword('')
    setConfirmNewPassword('')
}
    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        dispatch(changePasswordRequest(currentPassword, newPassword, token))
    }

    return (
        <>
            {loading && <Loading />}
            <div className="account-cnt">
                <div className="change-psw-section">
                    <h3 className="text-center account-title">Change Password</h3>
                    <div className="row">
                        <div className="col-md-8  col-lg-12  col-xxl-6 mx-auto">
                            <div className="kyc-body table-cnt-box bank-details br-6 change-password-wrapper">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group pass-field label-field">
                                        <label className="label">Current Password*</label>
                                        <input type={`${showPass1 ? "text" : "password"}`} name="currentPassword" value={currentPassword} onChange={(e) => handlePassword(e, 'current')} />
                                        <i className={`fa ${showPass1 ? "fa-eye-slash" : "fa-eye"}`} aria-hidden="true" onClick={() => setShowPass1(!showPass1)} />
                                        {/* <button type="button" className={currentPasswordEyeIcon ? "pass hide border-0 bg-transparent" : "pass border-0 bg-transparent"} onClick={() => handlePasswordVisibility('current')}></button> */}
                                        <p className="error-text error-red">{errCurrentPassword}</p>
                                    </div>
                                    <div className="form-group pass-field label-field">
                                        <label className="label">New Password*</label>
                                        <input type={`${showPass2 ? "text" : "password"}`} name="newPassword" value={newPassword} onChange={(e) => handlePassword(e, 'new')} />
                                        <i className={`fa ${showPass2 ? "fa-eye-slash" : "fa-eye"}`} aria-hidden="true" onClick={() => setShowPass2(!showPass2)} />
                                        {/* <button type="button" className={newPasswordEyeIcon ? "pass hide border-0 bg-transparent" : "pass border-0 bg-transparent"} onClick={() => handlePasswordVisibility('new')}></button> */}
                                        <p className="error-text error-red">{errNewPassword}</p>
                                    </div>
                                    <div className="form-group pass-field label-field mb-0">
                                        <label className="label">Confirm New Password*</label>
                                        <input type={`${showPass3 ? "text" : "password"}`} name="confirmPassword" value={confirmNewPassword} onChange={(e) => handlePassword(e, 'confirmNew')} />
                                        <i className={`fa ${showPass3 ? "fa-eye-slash" : "fa-eye"}`} aria-hidden="true" onClick={() => setShowPass3(!showPass3)} />
                                        {/* <button type="button" className={confirmNewPasswordEyeIcon ? "pass hide border-0 bg-transparent" : "pass border-0 bg-transparent"} onClick={() => handlePasswordVisibility('confirmNew')}></button> */}
                                        <p className="error-text error-red">{errConfirmNewPassword}</p>
                                    </div>
                                    <div className="text-center">
                                        <button disabled={disableReset} type="reset" style={{marginRight:'20px',"border":"none","padding":"17px"}} onClick={handleReset}    className="common-btn submit-btn">
                                            <span>Reset</span>
                                        </button>
                                        <button disabled={!disableSubmit} type="submit" className="common-btn submit-btn" style={{"border":"none" ,"padding":"17px"}}>
                                            <span>submit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default connect()(changePassword);
