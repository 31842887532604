import React from 'react'
import { Link } from 'react-router-dom';

// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';
import blog_img from '../../assets/images/blog/blog-img1.png'

const Disclaimer = () => {
    return (
        <div>
            {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****--> */}
            {/* <!-- Tab Panel Section --> */}
            <section className="site-content cmn-banner-bg cmn-pad about-page-section">
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <h3 className="text-center page-title">Disclaimer</h3>
                        <div className='content-sec'>
                            <h3 className="text-xl font-bold mt-4 mb-2">Disclaimer of Warranties</h3>
                            <ul className="list-disc pl-5 mb-4">
                                <li>Rummy 246 disclaims all warranties and conditions of any kind, whether express, implied, or statutory. This includes, without limitation, implied warranties of title, non-infringement, merchantability, and fitness for a particular purpose.</li>
                                <li>The content on Rummy 246 is provided on an "as-is" and "where-is" basis without any representations, warranties, conditions, or guarantees of any kind. We do not ensure or guarantee continuous, error-free, secure, or virus-free operation of the Website, its content, or any associated services, including software, games, and account functionalities.</li>
                                <li>Access to your account or the Services may be temporarily or permanently unavailable due to system maintenance, updates, or other unforeseen issues. We do not promise continuous access or availability.</li>
                            </ul>

                            <h3 className="text-xl font-bold mt-4 mb-2">Voluntary Participation and Liability</h3>
                            <ul className="list-disc pl-5 mb-4">
                                <li>By participating in the games on Rummy 246, you acknowledge that you do so voluntarily and understand that you may incur financial loss. You agree to hold Rummy 246, its owners, partners, directors, officers, employees, and agents harmless from any and all claims, losses, or costs arising from your use of the Website.</li>
                                <li>You are solely responsible for all actions related to your account, regardless of when they occur, including before, during, or after your use of the Website.</li>
                            </ul>

                            <h3 className="text-xl font-bold mt-4 mb-2">Limitation of Liability</h3>
                            <ul className="list-disc pl-5 mb-4">
                                <li>Rummy 246 is not liable for any claims, damages, or demands arising from your use of the Website or Services. This includes any financial losses that may result from using the Website.</li>
                                <li>We make no guarantees, warranties, or representations regarding financial outcomes resulting from the use of our Services. You accept the entire risk associated with the performance of any software or games accessed through the Website.</li>
                                <li>Any issues with your hardware or internet connection are your responsibility. Costs for servicing, repair, or correction of your hardware while using our Website are also your responsibility. We disclaim liability for any delays or failures related to your computer or equipment performance.</li>
                            </ul>

                            <h3 className="text-xl font-bold mt-4 mb-2">Game Interruption and Forfeiture</h3>
                            <ul className="list-disc pl-5 mb-4">
                                <li>In the event of a game interruption due to technical issues, the game will be paused, and a notification will be displayed. If you leave the game or log out during this time, you will be considered to have forfeited the game, and your buy-in amount will not be refunded.</li>
                                <li>If the game does not resume within the notified time frame, it will be canceled, and buy-in amounts will be refunded to your account within 24 hours. Rummy 246 is not liable for any potential winnings in canceled or forfeited games.</li>
                            </ul>

                            <h3 className="text-xl font-bold mt-4 mb-2">Exclusions of Liability</h3>
                            <p>Rummy 246 is not liable for:</p>
                            <ul className="list-disc pl-5 mb-4">
                                <li>Verbal offenses or illegal conduct by other players.</li>
                                <li>Misleading or defamatory communications from other players or third parties.</li>
                                <li>Misuse or abuse of your account by anyone with or without your knowledge.</li>
                                <li>Loss of information transmitted over the internet.</li>
                                <li>Technical failures, system breakdowns, data loss, or other adverse technological occurrences affecting your access to the Website.</li>
                                <li>The accuracy or currency of information or statistics on the Website.</li>
                                <li>Services that are subject to statutory or regulatory permissions. If such permissions are denied or revoked, the Services will be canceled, and Rummy 246 will not be liable for any damages or claims.</li>
                            </ul>

                            <h3 className="text-xl font-bold mt-4 mb-2">Indemnification</h3>
                            <p>You agree to indemnify and hold Rummy 246 harmless from any claims, damages, or expenses made by third parties related to:</p>
                            <ul className="list-disc pl-5 mb-4">
                                <li>Content you post.</li>
                                <li>Your connection to or use of Rummy 246.</li>
                                <li>Violation of these Terms or the Privacy Policy.</li>
                                <li>Unauthorized access to your account using your username and password.</li>
                            </ul>

                            <h3 className="text-xl font-bold mt-4 mb-2">Financial Risk and Responsibility</h3>
                            <p>The games involve financial risk and may be addictive. Play responsibly and at your own risk.</p>

                        </div>
                    </div>
                </div>
            </section >
            {/* <!--***** //Middle Section *****--> */}
            {/* <!--***** Footer *****--> */}
            <Footer />
        </div >
    )
}

export default Disclaimer