import React, { useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

// import Home
import Footer from '../auth/Footer'
import Header from '../auth/Header';
import play_bg from '../../assets/images/how-to-play-bg.png'
import blog_inner from '../../assets/images/blog/blog-inner-banner.jpg'


const HowToPlay = () => {
    const [tab, setTab] = useState('1')
    return (
        <div>
            {/* <!--***** Start Body *****-->
            <!--***** Header *****--> */}
            {/* <Header /> */}
            <Header />
            {/* <!--***** //Header *****--> */}
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content">
                        <h3 className="text-center page-title">How to play</h3>
                        <div className='about-details-sec'>
                            <p>Want to start playing Rummy but don&apos;t know how to play? Don&apos;t worry, because, we have the perfect guide that tells you the rules to play rummy and some tips that can help you win the game. Do remember that practice and experience in the Rummy game sharpen one skill and make them win.</p>
                            <p>Rummy is a game that is played with two decks of cards consisting of two Jokers. To make your way to the top of the rummy game, a player should decide to pick and dispose of cards from the two heaps given. One heap is a shut deck, where a player can&apos;t see the card that he is picking, while the other is an open deck that is a group of cards the players have disposed of. To win,  the players need to gather cards in a valid order or set.</p>
                            <p>The cards in the Rummy game consist of rank from low to high with Ace, 2, 3, 4, 5, 6, 7, 8, 9, 10, Jack, Queen, King. The card with ace, king, queen, and joker is of 10 points each, and the rest of them are of value equal to their face values.</p>
                            <figure className="image-banner">
                                <img src={blog_inner} alt="blog-inner-banner" />
                            </figure>
                            <p>The basic rule of Rummy is to position 13 cards in a particular sequence or set. And to win the game, the player must have 2 arrangements of cards, out of which one must be a pure sequence and the other a valid set of cards. Without the pure sequence, one cannot win the game.</p>
                            <p>A valid sequence of cards is considered only if it has three or more cards of the same suit. There are two types of sequences in rummy known as the pure sequence and the impure sequence.</p>
                            <div className="highlighted-sec mar-top-25">
                                <h3>Pure Sequence</h3>
                                <p>A pure sequence is formed when three or more cards of the same suit are placed together in order. Do keep in mind that the joker or wild card cannot be part of the sequence.</p>
                                <p>Examples of a pure sequence are: 2♥ 3♥ 4♥ </p>
                            </div>
                            <div className="highlighted-sec mar-top-25">
                                <h3>Impure Sequence</h3>
                                <p>An Impure sequence is formed when three or more cards with one or more Joker cards of the same suit make a group.</p>
                            </div>
                            <figure className="image-banner">
                                <img src={blog_inner} alt="blog-inner-banner" />

                            </figure>
                            <p>So, now that you have a basic understanding of the types of cards used in Rummy, let&apos;s look into some of the instructions that&apos;ll help you initiate your game:</p>
                            <ol>
                                <li>The Rummy card game is played between 2 to 6 players with 2 decks of cards. Each player manages 13 cards and an irregular card is chosen as a wild joker or joker card of the game.</li>
                                <li>Now, the player has to remove cards to form proper sequences and sets. They can also use a wild joker or joker card to form an impure sequence.</li>
                                <li>During the game, if a player makes at least one pure sequence along with many groups, he/she can make an announcement and win the game.</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!--***** Footer *****--> */}
            <Footer />
        </div>
    )
}

export default HowToPlay