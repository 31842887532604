/* eslint-disable react/prop-types */
import React from 'react';
import Footer from '../auth/Footer';
import Header from '../auth/Header';
// import Accordion from 'react-responsive-accordion';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Faq2 = () => {
    return (
        <div>
            <Header />
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content">
                        <h3 className="text-center page-title">FAQ&apos;s</h3>
                        <div className='faq'>
                            <Accordion id='accordion' preExpanded={['acc1']} allowZeroExpanded>
                                {/* {items.map((item) => ( */}
                                <AccordionItem className='card' uuid='acc11'>
                                    <AccordionItemHeading className='card-header'>
                                        <AccordionItemButton className='btn-link'>
                                            Why is Rummy246 Considered the Best Website for Playing Online Rummy?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        Rummy246.in is India's most preferred and premium website for playing Indian Rummy online for cash. The traditional rummy card game of India has always been a fun-filled source of entertainment for families and friends. Rummy246 brings you a digital adaptation of the classic 13 cards Indian rummy or 21 cards Indian rummy, playable on desktops, laptops, tablets, and mobile devices. Our platform offers:
                                        * User-Friendly Interface: Easy navigation and gameplay.
                                        * Multiple Game Variants: Enjoy different rummy formats including 13 Cards Rummy, 21 Cards Rummy, 10 Cards Rummy, Pool Rummy, Deals Rummy, Points Rummy, and 27 Cards Rummy.
                                        * Regular Tournaments: Participate in exciting tournaments and win cash prizes.
                                        * Secure Transactions: Guaranteed safe and secure deposits and withdrawals.
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem className='card' uuid='acc12'>
                                    <AccordionItemHeading className='card-header'>
                                        <AccordionItemButton className='btn-link'>
                                            How Easy is it to Deposit and Withdraw Cash?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        Rummy246 provides easy and convenient deposit options online. Players can deposit using multiple methods on the Rummy246.in website or the Android app, including Net Banking, Paytm, Credit Card, Mobile Wallet, and Cash Cards. All transactions are guaranteed to be safe and secure. Here's how it works:
                                        * Deposits: Simply click the Add Cash option, fill in the deposit amount and any bonus code, and proceed to the secure payment gateway.
                                        * Withdrawals: Withdraw anywhere from Rs. 100 to Rs. 10,000 in one go. Choose between Bank Transfer and UPI for fast withdrawals. A document verification process ensures security before withdrawal initiation.
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem className='card' uuid='acc13'>
                                    <AccordionItemHeading className='card-header'>
                                        <AccordionItemButton className='btn-link'>
                                            Is Rummy Legal to Play in India?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        Yes, it is completely legal to play rummy online in India, except in the states of Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim, and Telangana. The Supreme Court has ruled that rummy is a game of skill, making it legal to play online for free across India. Users from restricted states are not allowed to access or play cash games on Rummy246. Register now and enjoy the full benefits and a new experience of playing online rummy games at Rummy246.
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem className='card' uuid='acc14'>
                                    <AccordionItemHeading className='card-header'>
                                        <AccordionItemButton className='btn-link'>
                                            Why Do Everyone Consider Rummy246 the Best Indian Rummy Card Game?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        Rummy246 stands out with its exceptional features and benefits:
                                        * Immersive Gameplay: Enjoy a realistic and engaging rummy experience.
                                        * Real Money Games: Play rummy for real cash and win exciting rewards.
                                        * Seamless Access: Play rummy anytime, anywhere on your preferred device.
                                        * Community & Support: Join a community of rummy enthusiasts and access round-the-clock customer support.
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem className='card' uuid='acc15'>
                                    <AccordionItemHeading className='card-header'>
                                        <AccordionItemButton className='btn-link'>
                                            Different Forms of Free Online Rummy Games
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        Explore a variety of rummy formats on Rummy246:
                                        * 13 Cards Rummy: The most popular rummy variant in India.
                                        * 21 Cards Rummy: A more challenging version with additional rules.
                                        * 10 Cards Rummy: A quick and fast-paced variant.
                                        * Pool Rummy: Play until a predefined point limit is reached.
                                        * Deals Rummy: Enjoy a fixed number of deals with pre-decided chips.
                                        * Points Rummy: The fastest rummy variant where every point has a cash value.
                                        * 27 Cards Rummy: A unique and engaging rummy format with more cards and rules.
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem className='card' uuid='acc16'>
                                    <AccordionItemHeading className='card-header'>
                                        <AccordionItemButton className='btn-link'>
                                            Join the Rummy246 Community
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        Experience the thrill of online rummy at Rummy246. Our platform is designed to provide an unmatched gaming experience with a focus on safety, security, and user satisfaction. Register today and be a part of the Rummy246 family!
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* ))} */}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div >
    )
}

export default Faq2