/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  UncontrolledTooltip,
  PopoverBody,
  TabContent,
  TabPane,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { history } from "../App";
import { logout } from "../actions/auth";
import {
  getClubInfo,
  getProfile,
  refreshPracticeChips,
} from "../actions/account";
import Loading from "./Loading";
// import logo from "../assets/images/img/Rummy-One-Web-Logo.png";
// import refresh from '../assets/images/header-refresh-icon.svg';
// import refresh from '../../../images/refresh.svg';
import refresh from "../assets/images/icons/refresh.svg";
import wallet from "../assets/images/icons/wallet.png";
import AddIcon from "../assets/images/icons/add.png";
import logOut from "../assets/images/log-out-icon.svg";
import pokerChip from "../assets/images/icons/chips.png";
import clubIcon from "../assets/images/clubicon.svg";

import config from "../config";
import UserIcon from "../assets/images/icons/user-icon.png";
import { LOGOUT } from "../constantsNew";
import { unAuthorized } from "../helper";
import logo from "../assets/images/logo.png";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [showConfirmLogoutMessage, setShowConfirmLogoutMessage] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [totalChips, setTotalChips] = useState(0);
  const [practiceChips, setPracticeChips] = useState(0);
  const [showTakeSeat, setShowTakeSeat] = useState(false);
  const [tableId, setTableId] = useState("");
  const [lp, setLp] = useState();
  const [logoutModal, setLogoutModal] = useState(false);
  const [takeSeatModal, setTakeSeatModal] = useState(false);
  const [clubName, setClubName] = useState("");
  const [maintainance, setMaintainance] = useState(false);
  // const [endMaintainanace, setEndMaintainanace] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const profileData = useSelector((state) => state.account.dataProfile);
  const refreshChipsStatus = useSelector(
    (state) => state.account.resStatusRefreshChips
  );
  const takeSeatData = useSelector((state) => state.tournament.takeSeatData);
  const clubInfo = useSelector((state) => state.account.clubData);
  const gameSettings = useSelector((state) => state.account.data);
  const previousProps = useRef({ refreshChipsStatus, takeSeatData }).current;

  useEffect(() => {
    dispatch(getProfile(token));
    dispatch(getClubInfo(token));
    setLoading(true);
  }, []);

  useEffect(() => {
    if (clubInfo) {
      setLp(clubInfo?.nLP);
      setClubName(clubInfo?.sName || "Club");
    }
  }, [clubInfo]);

  const toggleNavbar = () => setIsOpen(!isOpen);
  const LogoutModalToggle = () => setLogoutModal(!logoutModal);
  function takeSeatModalToggle() {
    setTakeSeatModal(!takeSeatModal);
  }

  useEffect(() => {
    if (profileData) {
      setLoading(false);
      setUserName(profileData?.sUserName || "");
      setTotalChips(formatValue(profileData.nChips));
      console.log(profileData);
      //   setPracticeChips(profileData?.nPracticeChips?.toFixed(2) || 0);
      setPracticeChips(formatValue(profileData.nPracticeChips));
    }
  }, [profileData]);

  const [rHour, setRHour] = useState(0);
  const [rMin, setRMin] = useState(0);
  const [rSec, setRSec] = useState(0);

  function formatValue(nBalance) {
    if (nBalance < 0) {
      if (nBalance > -100000 && nBalance < -999) {
        return (nBalance / 1000).toFixed(2) / 1 + "K";
      } else {
        return nBalance.toFixed(2) / 1;
      }
    } else {
      if (nBalance > 999 && nBalance < 1000000) {
        return (nBalance / 1000).toFixed(2) / 1 + "K";
      } else {
        return nBalance.toFixed(2) / 1;
      }
    }
  }

  function getTime() {
    const startDate = new Date(gameSettings?.oMaintenance?.dEndAt);
    const finishHours =
      startDate.getHours() +
      startDate.getMinutes() / 60 +
      startDate.getSeconds() / 3600;
    const currentHours =
      new Date().getHours() +
      new Date().getMinutes() / 60 +
      new Date().getSeconds() / 3600;
    const remainingHours = finishHours - currentHours;

    const remainingHour = Math.floor(remainingHours);
    const remainingMinute = Math.floor((remainingHours - remainingHour) * 60);
    const remainingSecond = Math.floor(
      ((remainingHours - remainingHour) * 60 - remainingMinute) * 60
    );

    setRHour(remainingHour);
    setRMin(remainingMinute);
    setRSec(remainingSecond);
  }

  useEffect(() => {
    if (gameSettings) {
      if (
        gameSettings?.oMaintenance?.eMode == "enabled" &&
        gameSettings?.oMaintenance?.eState == "running"
      ) {
        setInterval(function () {
          getTime();
        }, 1000);
        setMaintainance(true);
        // const date = moment(gameSettings?.oMaintenance?.dEndAt).format("DD MMMM, hh:mm A")
        // setEndMaintainanace(date)
      }
    }
  }, [gameSettings]);

  useEffect(() => {
    if (previousProps.refreshChipsStatus !== refreshChipsStatus) {
      if (refreshChipsStatus === true) {
        setLoading(false);
        dispatch(getProfile(token));
        dispatch(getClubInfo(token));
      } else if (refreshChipsStatus === false) {
        setLoading(false);
        dispatch(getProfile(token));
        dispatch(getClubInfo(token));
      }
    }
    return () => {
      previousProps.refreshChipsStatus = refreshChipsStatus;
    };
  }, [refreshChipsStatus]);

  useEffect(() => {
    if (previousProps.takeSeatData !== takeSeatData) {
      if (takeSeatData) {
        setShowTakeSeat(true);
        setTakeSeatModal(true);
        setTableId(takeSeatData?.iTableId);
      }
    }
    return () => {
      previousProps.takeSeatData = takeSeatData;
    };
  }, [takeSeatData]);

  function onLogout(e) {
    e.preventDefault();
    dispatch(logout(token));
    setLoading(false);
    unAuthorized()
  }

  function handleRefreshChips() {
    setLoading(true);
    dispatch(refreshPracticeChips(token));
    toast(
      <div>
        <div>Refill practice chips if they fall below 5,000.</div>
      </div>,
      { autoClose: 3000 }
    );
  }

  function handleTakeSeat() {
    if (maintainance == false) {
      setShowTakeSeat(false);
      setTakeSeatModal(false);
      const top = window.top.outerHeight / 2 + window.top.screenY - 590 / 2;
      const left = window.top.outerWidth / 2 + window.top.screenX - 1079 / 2;
      window.open(
        `${config?.GAME_URL}?sAuthToken=${token}&sTableId=${tableId}&nChips=${totalChips}`,
        `${tableId}`,
        `popup=1, toolbar=0,status=0,width=1318,height=725, top=${top}, left=${left}`
      );
    } else if (maintainance == true) {
      setShowWarning(true);
    }
  }

  return (
    <div>
      {loading && <Loading />}
      <header className="logged-in">
        <Navbar expand="md">
          <Link to="/lobby" className="navbar-brand" >
            {/* <h2 style={{ color: "white" }}><img src={logo} /></h2> */}
            <img src={logo} alt="logo" />
          </Link>
          <NavbarToggler onClick={toggleNavbar} />
          <Collapse isOpen={isOpen} navbar className="">
            <Nav className="mx-auto align-items-center after-login " navbar>
              <NavItem
                className={classnames("underline", {
                  active:
                    history.location && history.location.pathname === "/lobby",
                })}
              >
                <Link
                  className={classnames("nav-link", {
                    active:
                      history.location &&
                      history.location.pathname === "/lobby",
                  })}
                  to="/lobby"
                >
                  Lobby
                </Link>
              </NavItem>
              <NavItem
                className={classnames("underline", {
                  active:
                    (history.location &&
                      (history.location.pathname === "/my-account" ||
                        history.location.pathname === "/game-transaction" ||
                        history.location.pathname ===
                        "/tournament-transaction" ||
                        history.location.pathname === "/passbook" ||
                        history.location.pathname.includes(
                          "/game-transaction/table-transaction"
                        ))) ||
                    history.location.pathname === "/bonus" ||
                    history.location.pathname === "/invite" ||
                    history.location.pathname === "/withdraw" ||
                    history.location.pathname === "/report-problem" ||
                    history.location.pathname === "/change-password",
                })}
              >
                <Link
                  className={classnames("nav-link", {
                    active:
                      (history.location &&
                        (history.location.pathname === "/my-account" ||
                          history.location.pathname === "/game-transaction" ||
                          history.location.pathname ===
                          "/tournament-transaction" ||
                          history.location.pathname === "/passbook" ||
                          history.location.pathname.includes(
                            "/game-transaction/table-transaction"
                          ))) ||
                      history.location.pathname === "/bonus" ||
                      history.location.pathname === "/invite" ||
                      history.location.pathname === "/withdraw" ||
                      history.location.pathname === "/report-problem" ||
                      history.location.pathname === "/change-password",
                  })}
                  to="/my-account"
                >
                  My Account
                </Link>
              </NavItem>
              {/* <NavItem className={classnames("underline", { "active": history.location && (history.location.pathname === '/offers') })}>
                                <Link className={classnames("nav-link", { "active": history.location && (history.location.pathname === '/offers') })} to="/offers">Offers</Link>
                            </NavItem> */}
              {/* <NavItem className="message">
                                <Button color="danger" onClick={handleMessageCenter} className="p-0 bg-transparent border-0"><img src={messageIcon} alt="messages" /></Button>
                                {isMessageRead === false && <span className="msg-badge"></span>}
                            </NavItem> */}
              {/* <NavItem className="club-wrap">
                <div className="club-info d-flex align-items-center">
                  <img src={clubIcon} alt="poker-chip" />
                  <div className="d-flex flex-wrap flex-column">
                    <span>{clubName}</span>
                    <p className="text-center">LP: {lp || 0}</p>
                  </div>
                </div>
              </NavItem> */}
              <NavItem className="add-wallet">
                <div className="wallet-sec d-flex align-items-center">
                  <img src={wallet} alt="wallet" />
                  <span>{totalChips}</span>
                  {/* <Link to="deposit-cash"><div><img src={plus} alt="plus" /></div></Link> */}
                  <Link to="/deposit-cash">
                    <img src={AddIcon} alt="add" />
                  </Link>
                </div>
              </NavItem>
              {/* <NavItem >
                                
                            </NavItem> */}
              <NavItem className="username">
                {/* <Button id="UncontrolledTooltip" type="button" className="d-flex align-items-center bg-transparent p-0 border-0">
                                    <div className="user-img" style={{ backgroundImage: `url(${userAvatar})` }}></div>
                                    <span className="user-name">Welcome, {userName}!</span>
                                </Button> */}
                <a className="nav-link">
                  <img src={UserIcon} alt="user" />
                  <span> Welcome, {userName}!</span>
                </a>
                {/* <UncontrolledTooltip placement="bottom" target="UncontrolledTooltip" className="name-tooltip">
                                    {userName}
                                </UncontrolledTooltip> */}
              </NavItem>

              {/* <NavItem>
                                <NavLink onClick={() => setShowConfirmLogoutMessage(true)} className="logout"><img src={logOut} alt="logOut" /></NavLink>
                            </NavItem> */}
            </Nav>
            <Button
              type="button"
              className="red-button"
              onClick={LogoutModalToggle}
            // setShowConfirmLogoutMessage(true)
            >
              Logout
            </Button>
          </Collapse>
        </Navbar>
      </header>
      {/* modal for logout popup */}
      <Modal
        isOpen={logoutModal}
        toggle={LogoutModalToggle}
        className="logout-modal modal-dialog-centered"
      >

        <div className="logoutbackground">
          <div className="addlogout-Tittle">Logout</div>
          <div className="txt-msg-box">
            <p>Are you sure you want to Logout?</p>
          </div>
          <div className="new-create-box text-center">
            <button
              type="button"
              className="green-button"
              onClick={onLogout}
            >
              <span>Yes</span>
            </button>
            <button
              type="button"
              className="red-button"
              onClick={LogoutModalToggle}
            >
              <span>No</span>
            </button>
          </div>
        </div>
      </Modal>

      <div
        className={
          showConfirmLogoutMessage ? "edit-auth-dtl active" : "edit-auth-dtl"
        }
      >
        {/* <div>
                    <p><strong>Are you sure you want to Logout?</strong></p>
                    <div className="d-flex side-btn justify-content-center">
                        <input type="button" className="common-btn yellow-btn small-btn" value="Yes" onClick={onLogout} />
                        <input type="button" className="common-btn yellow-btn small-btn" value="No" onClick={() => setShowConfirmLogoutMessage(false)} />
                    </div>
                </div> */}
      </div>
      {/* <div className={showTakeSeat ? "edit-auth-dtl active" : "d-none"}  >
                <div className="text-center">
                    <p><strong>A tournament you joined before is started. <br /><p>Please take a seat.</p> </strong></p>
                    <div className="d-flex side-btn justify-content-center">
                        <input type="button" className="common-btn yellow-btn small-btn" value="Take A Seat" onClick={handleTakeSeat} />
                        <input type="button" className="common-btn yellow-btn small-btn" value="Cancel" onClick={() => setShowTakeSeat(false)} />
                    </div>
                </div>
            </div> */}
      <Modal
        isOpen={takeSeatModal}
        toggle={takeSeatModalToggle}
        className="logout-modal modal-dialog-centered"
      >
        <div className="modal-body ">
          <div className="txt-msg-box">
            <p>
              A tournament you joined before is started. <br />
              <p>Please take a seat.</p>
            </p>
          </div>
          <div className="new-create-box text-center">
            <button
              type="button"
              className="btn theme-orange-btn"
              onClick={handleTakeSeat}
            >
              <span>Take Seat</span>
            </button>
            <button
              type="button"
              className="btn theme-orange-btn close"
              onClick={takeSeatModalToggle}
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showWarning}
        className="logout-modal modal-dialog-centered"
      >
        <div className="modal-body logoutbackground maintainace-bg ">
          <div className="txt-msg-box">
            <p>
              <strong>The game is currently under maintenance.</strong> <br />{" "}
              <br />
              It will be back in {rHour > 0 && ("0" + rHour).slice(-2)}
              {rHour > 0 && ":"}
              {("0" + rMin).slice(-2)}:{("0" + rSec).slice(-2)}.
              {/* {endMaintainanace}. */}
            </p>
          </div>
          <div className="new-create-box text-center">
            <button
              type="button"
              className="btn theme-orange-btn"
              onClick={() => setShowWarning(false)}
            >
              <span>okay</span>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default connect()(Header);
