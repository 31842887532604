import React from 'react';
import Footer from '../auth/Footer';
import Header from '../auth/Header';
import { toast } from 'react-toastify';


const Contact = () => {
  function hendleSubmit(){
    toast(
      <div>
          <div>Messge sent successfully.</div>
      </div>
      , { autoClose: 3000 });
  }


  return (
    <div>
      <Header />
      <section className="site-content how-to-play-block">
        <div className="container">
          <div className="page-title cmn-pad mb-0">
            <h3 className="text-center">contact us</h3>
          </div>
          <div className="contact-area">
            <h4 className="big">Contact Us</h4>
            <p className="mar-top-25">We have a big customer support team that provides the right amount of time and guidance to every query our players can come across. Just fill out the form below or reach out to us through our contact number and email id, and we assure you that our team will get back to you as soon as possible.</p>
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-form cmn-pad-top-50">
                  <form className="form-inline">
                    <div className="form-group">
                      <label htmlFor="email">Email address:</label>
                      <input type="email" className="form-control" id="email" required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="subject">Subject:</label>
                      <input type="text" className="form-control" id="subject" />
                    </div>
                    <div className="form-group">
                      <label>Message:</label>
                      <textarea className="form-control" rows="4" cols="50"></textarea>
                    </div>
                    <button type="submit" className="theme-btn" onClick={(e)=>{
                      e.preventDefault();
                      hendleSubmit()}}>send</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-detail contact-area">
            <ul>
              <li className="mobile">
                <h5>Contact No</h5>
                <a href="tel:6358859255">63 58 859 255</a>
              </li>
              <li className="email">
                <h5>Email</h5>
                <a href="mailto:support@rummy246.in">support@rummy246.in</a>
              </li>
              <li className="location">
                <h5>Profuse Entertainment Pvt. Lmt.</h5>
                <p>Bsquare 2, 13th Floor, Iscon, Ambli Rd, Vikram Nagar, Ahmedabad, Gujarat 380054</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Contact