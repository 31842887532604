
import React from 'react';
import Footer from '../auth/Footer';
import Header from '../auth/Header';
import icon from '../../assets/images/icon-logo.png'
import downloadSS1 from '../../assets/images/screenshots/screenshot-1.png'
import downloadSS2 from '../../assets/images/screenshots/screenshot-2.png'
import downloadSS3 from '../../assets/images/screenshots/screenshot-4.png'




const download = () => {
    return (
      <>
      
      <div>
            <Header />
           
         <section className="download-area cmn-pad">
                <div className="container">
                <div className="row" style={{height:'36vh'}}>
                No Content
            </div>
                    {/* <div className="row">
                        <div className="col-md-6">
                            <div className="hero-content">
                                <figure>
                                    <img src={icon} alt="icon"></img>
                                </figure>
                                <h2 className="mar-top-15">Rummy246 Online Rummy App</h2>
                                <p className="company-name">Play & Win Real Indian Cash </p>
                                <p className="company-add">Contains ads</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <ul className="mar-top-50 d-flex align-items-center justify-content-between stats-content">
                                <li>
                                    <p className="highlight"> 4.7 <i className="fa fa-star" aria-hidden="true"></i></p>
                                    <p>677 reviews</p>
                                </li>
                                <li>
                                    <p className="highlight"><i className="fa fa-download" aria-hidden="true"></i></p>
                                    <p>51.8 MB</p>
                                </li>
                                <li>
                                    <p className="rating">17+</p>
                                    <p>Rated for 17+</p>
                                </li>
                                <li>
                                    <p>1M+</p>
                                    <p>Downloads</p>
                                </li>
                            </ul>
                            <div className="download-btn mar-top-25"><a
                                href="https://rummyrounds-apk.s3.ap-south-1.amazonaws.com/rummyrounds.apk"
                                download="round-rummy-game.apk">Download</a></div>
                                
                            <ul className="mar-top-25 game-screenshot d-flex justify-content-between">
                                <li> <img src={downloadSS1} alt='screenshot' className='img-fluid'></img> </li>
                                <li> <img src={downloadSS2} alt='screenshot' className='img-fluid'></img> </li>
                                <li> <img src={downloadSS3} alt='screenshot' className='img-fluid'></img> </li>
                            </ul>
                        </div>
                        <div className="offset-md-3 col-md-6">
                            <div className="review-content mar-top-50">
                                <h3>About this game</h3>
                                <p className="mar-top-25">Play your favorite Rummy game, only on RummyRounds.com!</p>
                                <p>RummyRounds.com is India&apos;s newest Rummy game app that includes the fun and thrill of the game
                                    into a single platform. Our players can enjoy an awesome gaming experience while taking
                                    benefit of the security and reliability of our application.</p>
                                <p>Showcase and test your skills and patience with a game of Rummy. Download the app now!</p>
                                <p>At RummyRounds.com, our team is working hard every day to provide the best Rummy experience.
                                    Our application includes amazing graphics, fair gameplay, the fastest transactions, and
                                    hassle-free withdrawals. Our 24/7 customer service ensures none of our players&apos; queries are
                                    left unattended.</p>
                                <p>Though there are various types of card games available, Rummy stands as the most popular card
                                    game played in India. It can be played with a minimum of 2 players to a maximum of 6
                                    players.</p>
                                <p>Rummy is a game that is played with two decks of cards consisting of two Jokers. To make your
                                    way to the top of the rummy game, a player should decide to pick and dispose of cards from
                                    the two heaps given. One heap is a shut deck, where a player can&apos;t see the card that he is
                                    picking, while the other is an open deck that is a group of cards the players have disposed
                                    of. To win, the players need to gather cards in a valid order or set.</p>
                                <h3 className="mar-top-25">*FEATURES*</h3>
                                <ol>
                                    <li>1) Play pools, deals, and points rummy variations</li>
                                    <li>2) We provide 24/7 Rummy gameplay</li>
                                    <li>3) A completely safe and secure application</li>
                                    <li>4) Private room/tournament feature available </li>
                                    <li>5) Simple how-to-play guidelines and rummy tutorials help even a beginner start their
                                        journey of Rummy.</li>
                                    <li>6) Practice matches for each rummy variant and sufficient practice chips available for
                                        players</li>
                                    <li>7) Players can become agents on RummyRounds.com by bringing many players under them and
                                        earning money.</li>
                                    <li>8) Weekly tournaments allow players to win lots of cash and prizes.</li>
                                    <li>9) KYC processes for players to ensure transparency and authenticity.</li>
                                </ol>
                            </div>
                        </div>
                        <div className="offset-md-2 col-md-8">
                            <h4 className="mar-top-25 border-top rating-title">Ratings and reviews</h4>
                            <div className="row">
                                <div className="col-md-3 text-center">
                                    <h1 className="mar-top-25">4.7</h1>
                                    <ul className="d-flex align-items-center star-list justify-content-center">
                                        <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star-half" aria-hidden="true"></i></li>
                                    </ul>
                                    <p className="small">677</p>
                                </div>
                                <div className="col-md-6">
                                    <ul className="rating-list">
                                        <li className="d-flex align-items-center">
                                            <p>5</p>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-success" role="progressbar"
                                                    aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "75%" }}
                                                >
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <p>4</p>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-success" role="progressbar"
                                                    aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ width: "50%" }}
                                                >
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <p>3</p>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-success" role="progressbar"
                                                    aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style={{ width: "10%" }}
                                                >
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <p>2</p>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-success" role="progressbar"
                                                    aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style={{ width: "10%" }}
                                                >
                                                </div>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <p>1</p>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-success" role="progressbar"
                                                    aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style={{ width: "10%" }}
                                                >
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            <Footer />
        </div>
      </>
    )
}

export default download