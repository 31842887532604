import React from 'react'
import Footer from '../auth/Footer'
import Header from '../auth/Header'

const Privacy = () => {
    return (
        <div>
            <Header />
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <h3 className="text-center page-title">Privacy Policy</h3>
                        <div className='about-details-sec'>
                            <p>Welcome to Rummy 246. Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website or use our services. By accessing or using Rummy 246, you agree to the terms of this Privacy Policy.</p>
                            
                            <h3>1. Acceptance of Terms</h3>
                            <p>By accessing or using our website, you acknowledge that you have read, understood, and agree to this Privacy Policy. If you do not agree with our practices, please discontinue use of our website immediately. We reserve the right to modify this Privacy Policy at any time without prior notice. Your continued use of the website following any changes constitutes your acceptance of the updated Privacy Policy.</p>
                            
                            <h3>2. Information We Collect</h3>
                            <h5>Personal Information:</h5>
                            <p>To subscribe to Rummy 246 and access our services, we may ask you to provide personal information such as:</p>
                            <ul>
                                <li>Username</li>
                                <li>Password</li>
                                <li>Valid Email ID</li>
                            </ul>
                            <h5>If you participate in games or tournaments (Practice Game, Cash Tournament, or Promotional Tournament), we may also collect:</h5>
                            <ul>
                                <li>Name</li>
                                <li>Date of Birth</li>
                                <li>Nationality</li>
                                <li>Contact Number in India</li>
                                <li>Postal Address in India</li>
                                <li>PAN (as applicable)</li>
                            </ul>
                            
                            <h3>3. Use of Information</h3>
                            <p>We use the information we collect to:</p>
                            <ul>
                                <li>Provide, operate, and maintain our services</li>
                                <li>Improve and personalize your experience on Rummy 246</li>
                                <li>Communicate with you, including sending updates, promotions, and offers</li>
                                <li>Process transactions and manage your account</li>
                                <li>Analyze site usage and traffic to improve our services</li>
                                <li>Respond to inquiries and provide customer support</li>
                            </ul>
                            
                            <h3>4. Sharing of Information</h3>
                            <h5>Third-Party Service Providers:</h5>
                            <p>We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and customer support. These third parties are required to protect your information and use it only for the purpose of providing services to us.</p>
                            <h5>Legal Requirements:</h5>
                            <p>We may disclose your information to comply with legal obligations, respond to lawful requests and legal processes, or protect the rights, property, or safety of Rummy 246, our users, or others.</p>
                            <h5>Business Transfers:</h5>
                            <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity. We will notify you of any such change in ownership or control of your personal information.</p>
                            
                            <h3>5. Data Security</h3>
                            <p>We implement appropriate technical and organizational measures to safeguard your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure. While we strive to protect your information, we cannot guarantee absolute security.</p>
                            
                            <h3>6. Your Rights</h3>
                            <h5>Access and Correction:</h5>
                            <p>You have the right to access, update, or correct your personal information. If you wish to review or amend your information, please contact us at support@rummy246.in.</p>
                            <h5>Opt-Out:</h5>
                            <p>You may opt out of receiving promotional emails from us by following the unsubscribe instructions included in those emails. However, we may still send you non-promotional communications related to your account or our services.</p>
                            
                            <h3>7. Links to Other Websites</h3>
                            <p>Our website may contain links to other websites that are not operated by us. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of any third-party sites you visit.</p>
                            
                            <h3>8. Children's Privacy</h3>
                            <p>Rummy 246 is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected information from a child under 18, we will take steps to delete that information.</p>
                            
                            <h3>9. Changes to This Privacy Policy</h3>
                            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the new Privacy Policy on our website. Your continued use of the website after any changes signifies your acceptance of the updated Privacy Policy.</p>
                            
                            <h3>10. Data Retention</h3>
                            <p>Rummy246 retains user information for the period necessary to fulfill the purposes outlined in this Privacy Policy, subject to longer retention periods required by law.</p>

                            <h3>11. Contact Us</h3>
                            <p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at info@rummy246.in.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Privacy