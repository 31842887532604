import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";


import UserHitenImg from "../../assets/images/user-1.png";
import UserChiragImg from "../../assets/images/pc-2.png";
import UserProfile2Img from "../../assets/images/pc-3.png";
import UserProfileChetan from "../../assets/images/pc-4.png";
import UserProfileYash from "../../assets/images/pc-5.jpeg";
import girl from "../../assets/images/girl_image.png"
import computer_image from "../../assets/images/image-4.png"
import start_image from "../../assets/images/start_image.png"

import gifticon from '../../assets/images/gift-icon.png'
import moneyicon from '../../assets/images/money-icon.png'
import shieldicon from '../../assets/images/shield-icon.png'


// React slick files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";


import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

// import { Nav, NavItem, NavLink } from 'reactstrap';

function Homepage() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const testimonials = [
    {
      name: "Rajesh Panwar",
      image: UserHitenImg,
      quote: "Intuitive interface and exciting gameplay. Rummy246.com is my go-to for card games!",
      rating: 4.8,
    },
    {
      name: "Yash Sharma",
      image: UserProfileYash,
      quote: "Rummy here is both fun and rewarding. I enjoy playing with friends regularly.",
      rating: 4.5,
    },
    {
      name: "Chetan Modi",
      image: UserProfileChetan,
      quote: "Great platform for skill-building. My strategy has improved significantly.",
      rating: 4,
    },
    {
      name: "Chirag Kumar",
      image: UserChiragImg,
      quote: "The perfect blend of entertainment and potential earnings. Highly recommended!",
      rating: 4.7,
    },
    {
      name: "Dhruv Shah",
      image: UserProfile2Img,
      quote: "Impressed by the security features. It's a safe and enjoyable gaming environment.",
      rating: 4.5,
    },
  ];


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        document.body.classList.add('sticky');
      } else {
        document.body.classList.remove('sticky');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <section>
        <div class="features-container">
          <div class="feature">
            <img src={gifticon} alt="Welcome Bonus" />
            <div class="feature-text">
              <h3>Welcome Bonus</h3>
              <p>Up to 5000 INR</p>
            </div>
          </div>
          <div class="feature">
            <img src={moneyicon} alt="Instant Deposit" />
            <div class="feature-text">
              <h3>Instant Deposit</h3>
            </div>
          </div>
          <div class="feature">
            <img src={shieldicon} alt="Top-Tier Security" />
            <div class="feature-text">
              <h3>Top-Tier Security</h3>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Onliine Rummy --> */}
      <section className="high-light-banner">
        <div>
          <h1>Leading Platform for Online Rummy</h1>
          <h1>MOST ENTERTAINING RUMMY GAMING PLATFORM</h1>

        </div>
        <div className="img">
          <img src={computer_image} alt="trust-icon" />
        </div>
      </section>
      {/* Slider review */}
      <section className="slider-bar">
        <div className="pf-container">
          <h1>Player Feedback</h1>
          <Slider {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="pf-slide">
                <div className="pf-card">
                  <div className="pf-content">
                    <div className="pf-profile">
                      <img src={testimonial.image} alt={testimonial.name} className="pf-avatar" />
                      <div className="pf-name-badge">
                        <p>{testimonial.name}</p>
                      </div>
                    </div>
                    <div className="pf-text">
                      <p className="pf-quote">"{testimonial.quote}"</p>
                      <p className="pf-cta">Play Rummy! Rummy246!</p>
                    </div>
                    <div className="pf-rating">
                      <img src={start_image} alt={testimonial.name} className="pf-star" />
                      <span className="pf-rating-value">{testimonial.rating}</span>
                    </div>
                  </div>

                </div>
              </div>
            ))}
          </Slider>

        </div>
      </section>
      <section className="learn-rummy-overview">

        <div className="play-rummy-online">
          <div className="game-overview">
            <div className="learn-box">
              <div>
                <img src={girl} alt="trust-icon" />
              </div>
              <div>
                <h1>Learn Rummy in 4 Easy Steps  </h1>
                <Nav className="ml-auto align-items-start after-login download-btn " navbar>
                  <NavItem>
                    {/* <a href='https://www.google.com/ ' rel="noreferrer" target="_blank">How To Play</a> */}
                    <Link to="/how-to-play" rel="noreferrer" target="_blank">how to play</Link>
                  </NavItem>
                </Nav>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="faq">
        <div className="container">
          <div className="page-title mb-0">
            <h3 className="text-center">fAQ&apos;s</h3>
          </div>
          <Accordion id='accordion' preExpanded={['acc1']} allowZeroExpanded>

            <AccordionItem className='card' uuid='acc11'>
              <AccordionItemHeading className='card-header'>
                <AccordionItemButton className='btn-link'>
                  Why is Rummy246 Considered the Best Website for Playing Online Rummy?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                Rummy246.in is India's most preferred and premium website for playing Indian Rummy online for cash. The traditional rummy card game of India has always been a fun-filled source of entertainment for families and friends. Rummy246 brings you a digital adaptation of the classic 13 cards Indian rummy or 21 cards Indian rummy, playable on desktops, laptops, tablets, and mobile devices. Our platform offers:
                * User-Friendly Interface: Easy navigation and gameplay.
                * Multiple Game Variants: Enjoy different rummy formats including 13 Cards Rummy, 21 Cards Rummy, 10 Cards Rummy, Pool Rummy, Deals Rummy, Points Rummy, and 27 Cards Rummy.
                * Regular Tournaments: Participate in exciting tournaments and win cash prizes.
                * Secure Transactions: Guaranteed safe and secure deposits and withdrawals.
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className='card' uuid='acc12'>
              <AccordionItemHeading className='card-header'>
                <AccordionItemButton className='btn-link'>
                  How Easy is it to Deposit and Withdraw Cash?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                Rummy246 provides easy and convenient deposit options online. Players can deposit using multiple methods on the Rummy246.in website or the Android app, including Net Banking, Paytm, Credit Card, Mobile Wallet, and Cash Cards. All transactions are guaranteed to be safe and secure. Here's how it works:
                * Deposits: Simply click the Add Cash option, fill in the deposit amount and any bonus code, and proceed to the secure payment gateway.
                * Withdrawals: Withdraw anywhere from Rs. 100 to Rs. 10,000 in one go. Choose between Bank Transfer and UPI for fast withdrawals. A document verification process ensures security before withdrawal initiation.
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className='card' uuid='acc13'>
              <AccordionItemHeading className='card-header'>
                <AccordionItemButton className='btn-link'>
                  Is Rummy Legal to Play in India?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                Yes, it is completely legal to play rummy online in India, except in the states of Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim, and Telangana. The Supreme Court has ruled that rummy is a game of skill, making it legal to play online for free across India. Users from restricted states are not allowed to access or play cash games on Rummy246. Register now and enjoy the full benefits and a new experience of playing online rummy games at Rummy246.
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className='card' uuid='acc14'>
              <AccordionItemHeading className='card-header'>
                <AccordionItemButton className='btn-link'>
                  Why Do Everyone Consider Rummy246 the Best Indian Rummy Card Game?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                Rummy246 stands out with its exceptional features and benefits:
                * Immersive Gameplay: Enjoy a realistic and engaging rummy experience.
                * Real Money Games: Play rummy for real cash and win exciting rewards.
                * Seamless Access: Play rummy anytime, anywhere on your preferred device.
                * Community & Support: Join a community of rummy enthusiasts and access round-the-clock customer support.
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className='card' uuid='acc15'>
              <AccordionItemHeading className='card-header'>
                <AccordionItemButton className='btn-link'>
                  Different Forms of Free Online Rummy Games
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                Explore a variety of rummy formats on Rummy246:
                * 13 Cards Rummy: The most popular rummy variant in India.
                * 21 Cards Rummy: A more challenging version with additional rules.
                * 10 Cards Rummy: A quick and fast-paced variant.
                * Pool Rummy: Play until a predefined point limit is reached.
                * Deals Rummy: Enjoy a fixed number of deals with pre-decided chips.
                * Points Rummy: The fastest rummy variant where every point has a cash value.
                * 27 Cards Rummy: A unique and engaging rummy format with more cards and rules.
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className='card' uuid='acc16'>
              <AccordionItemHeading className='card-header'>
                <AccordionItemButton className='btn-link'>
                  Join the Rummy246 Community
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                Experience the thrill of online rummy at Rummy246. Our platform is designed to provide an unmatched gaming experience with a focus on safety, security, and user satisfaction. Register today and be a part of the Rummy246 family!
              </AccordionItemPanel>
            </AccordionItem>

          </Accordion>
        </div>
      </section>

      <section className="sidebar-sec">
        <div className="container">
          <h3 className="text-center theme-color">
            Rummy246 Rewards
          </h3>
          <div className="game-overview">

            <p style={{ "color": "black" }}>
              Rummy is amongst most popular in india
              Rummy246 celebrates your passion for the game with various rewards! As you play, you accumulate reward points based on your performance. New players receive a generous welcome bonus and joining sign-in bonus to enhance their experience.             </p>
            <p style={{ "color": "black" }}>
              Additionally, take advantage of special festival offers, providing extra incentives and prizes during festive seasons to make your gameplay even more exciting.
            </p>
          </div>
          {/* ***** */}
          <div className="rr-info-tabs">
            {/* <div className="">
              <ul className="player-role d-flex">
                <li>
                  <div className="d-flex align-items-center">
                    <div className="img-area">
                      <figure>
                        <img src={ResponsibleIcon} alt="responsible-icon" />
                      </figure>
                    </div>
                    <div className="player-desc">
                      <h4>Respoinsible Play:</h4>
                      <p style={{ "color": "black" }}>
                        Rummy246 encourages all its players to play
                        responsibly. Stay in control, stay entertained!.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    <div className="img-area">
                      <figure>
                        <img src={LegalIcon} alt="legal-icon" />
                      </figure>
                    </div>
                    <div className="player-desc">
                      <h4>100% Legal:</h4>
                      <p style={{ "color": "black" }}>
                        Rummy was declared to be a game of skill by the Honbl.
                        Supreme Court of India in 1968 and is 100% legal to play
                        for free or for cash.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div> */}
            <div className="download-btn-home">
              <div>
                Play Rummy246
              </div>
              <Nav className="ml-auto align-items-start after-login download-btn" navbar>
                <NavItem>
                  <a href='https://www.google.com/' rel="noreferrer" target="_blank">
                    <span className="download-icon">&gt;&gt;</span> DOWNLOAD
                  </a>
                </NavItem>
              </Nav>
            </div>
          </div>
          {/* ****** */}
        </div>
      </section>
      <div className="play-rummy-online">
        <div className="game-overview">
          <h3 className="text-center theme-color">
            Play Rummy Online on Rummy246
          </h3>
          <p style={{ "color": "black" }}>
            Rummy has quickly become a favorite among the Indian audience due to its suitability for various occasions. In Indian culture, gatherings and celebrations, even small ones, are common, and rummy has found its place as a popular form of entertainment during these events. The game is played with 2 to 6 players using 1-2 standard decks of 52 cards. Each player is dealt 13 cards, and the goal is to arrange them in sequences or sets. A valid declaration requires at least two sequences.
          </p>
          <p style={{ "color": "black" }}>
            Additionally, take advantage of special festival offers, providing extra incentives and prizes during festive seasons to make your gameplay even more exciting.
          </p>
        </div>
      </div>
    </div>

  );
}

export default Homepage;
