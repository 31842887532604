/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  TabContent,
  TabPane,
  Table,
  List,
  Button,
  UncontrolledTooltip,
  Row,
  Col
} from 'reactstrap'
import Select from 'react-select'
import classnames from 'classnames'
// import info from '../../../assets/images/info-icon.svg';
// import filter from '../../../assets/images/filter-icon.svg';
import refresh from '../../../assets/images/refresh-icon.svg'
import upArrow from '../../../assets/images/up-arrow.svg'
import downArrow from '../../../assets/images/down-arrow.svg'
import star from '../../../assets/images/star.svg'
import infoIcon from '../../../assets/images/table-info-icon.svg'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getLobbyTableList, joinTable } from '../../../actions/lobby'
import Loading from '../../../components/Loading'
import info from '../../../assets/images/icons/info.png'
import filter from '../../../assets/images/icons/filter.png'
import ResetIcon from '../../../assets/images/icons/reset.png'
import TwoPlayer from '../../../assets/images/player-2.png'
import SixPlayer from '../../../assets/images/player-6.png'
import { useQueryState } from 'react-router-use-location-state'

function Cash(props) {
  const [playModal, setPlayModal] = useState(false)
  const [subTab, setSubTab] = useState('9')
  const [tableData, setTableData] = useState([])
  const [filterToggle, setFilterToggle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [maxPlayerFilter, setMaxPlayerFilter] = useState(true)
  const [gameDetails, setGameDetails] = useState('')
  const [showConfirmPlay, setShowConfirmPlay] = useState(false)
  const [tableId, setTableId] = useState('')
  const [twoPlayerChecked, setTwoPlayerChecked] = useState(false)
  const [sixPlayerChecked, setSixPlayerChecked] = useState(false)
  const [rummyType, setRummyType] = useState()
  const [gameType, setGameType] = useState()
  const [maxPlayer, setMaxPlayer] = useState()
  const [tablePoint, setTablePoint] = useState()
  const [tableFee, setTableFee] = useState()
  const [showWarning, setShowWarning] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const [activeProfileTab, setActiveProfiletab] = useQueryState('myProfile', '1')

  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const lobbyTableData = useSelector((state) => state.lobby.data)
  const mainTab = props?.mainTab
  const maintainance = props?.maintainance
  const hour = props?.hour
  const min = props?.min
  const sec = props?.sec

  useEffect(() => {
    if (lobbyTableData) {
      setLoading(false)
      setTableData(lobbyTableData)
    }
  }, [lobbyTableData])

  const toggleSubTab = (tab) => {
    if (subTab !== tab) setSubTab(tab)
  }

  useEffect(() => {
    if (mainTab === '2' && subTab === '9') {
      setLoading(true)
      dispatch(getLobbyTableList('point', 'cash', token))
      setFilterToggle(false)
      setGameDetails('Play for points which have a pre-decided value. One winner wins the complete prize pool at the end of each game.')
    }
    if (mainTab === '2' && subTab === '10') {
      setLoading(true)
      dispatch(getLobbyTableList('pool', 'cash', token))
      setFilterToggle(false)
      setGameDetails(
        'Play until score reaches the maximum limit [101 or 201]. Winner of a deal gets 0 points and the rest accumulate points which are added to their score. A fixed entry fee forms the prize pool.'
      )
    }
    if (mainTab === '2' && subTab === '11') {
      setLoading(true)
      dispatch(getLobbyTableList('deal', 'cash', token))
      setFilterToggle(false)
      setGameDetails(
        'Play with chips that are allocated at the beginning of the deal for pre-decided number of deals. One winner wins all the chips at the end of each deal. At the end of pre-decided number of deals, player with  maximum number of chips is selected as the winner for the game.'
      )
    }
    if (mainTab === '2' && subTab === '12') {
      setLoading(true)
      dispatch(getLobbyTableList('point21', 'cash', token))
      setFilterToggle(false)
      setGameDetails('Play for points which have a pre-decided value. One winner wins the complete prize pool at the end of each game.')
    }
  }, [mainTab, subTab])

  function handleAscending(sortType) {
    const tableObj = [...tableData]
    const ascendingSorted = tableObj.sort((a, b) => a[sortType] - b[sortType])
    setTableData(ascendingSorted)
  }
  function handleDescending(sortType) {
    const tableObj = [...tableData]
    const descendingSorted = tableObj.sort((a, b) => b[sortType] - a[sortType])
    setTableData(descendingSorted)
  }

  function handleFilter(e, type) {
    setFilterToggle(false)
    setMaxPlayerFilter((maxPlayerFilter) => !maxPlayerFilter)
    switch (type) {
      case 'two':
        setTwoPlayerChecked(!twoPlayerChecked)
        break
      case 'six':
        setSixPlayerChecked(!sixPlayerChecked)
        break
    }
    if (maxPlayerFilter) {
      const tableObj = [...tableData]
      const result = tableObj.filter((tb) => tb.nMaxPlayer == e.target.value)
      setTableData(result)
    } else {
      if (mainTab === '2' && subTab === '9') {
        dispatch(getLobbyTableList('point', 'cash', token))
      }
      if (mainTab === '2' && subTab === '10') {
        dispatch(getLobbyTableList('pool', 'cash', token))
      }
      if (mainTab === '2' && subTab === '11') {
        dispatch(getLobbyTableList('deal', 'cash', token))
      }
      if (mainTab === '2' && subTab === '12') {
        dispatch(getLobbyTableList('point21', 'cash', token))
      }
    }
  }

  function resetFilter() {
    setFilterToggle(false)
    setMaxPlayerFilter(true)
    setTwoPlayerChecked(false)
    setSixPlayerChecked(false)
    if (mainTab === '2' && subTab === '9') {
      dispatch(getLobbyTableList('point', 'cash', token))
    }
    if (mainTab === '2' && subTab === '10') {
      dispatch(getLobbyTableList('pool', 'cash', token))
    }
    if (mainTab === '2' && subTab === '11') {
      dispatch(getLobbyTableList('deal', 'cash', token))
    }
    if (mainTab === '2' && subTab === '12') {
      dispatch(getLobbyTableList('point21', 'cash', token))
    }
  }

  function GamePlayModal(rummy, game, player, point, feeTable) {
    // setPlayModal(!playModal)
    // setRummyType(rummy)
    // setGameType(game)
    // setMaxPlayer(player)
    // setTablePoint(point)
    // if (feeTable) {
    //     setTableFee(feeTable)
    // }

    if (maintainance == false) {
      setPlayModal(!playModal)
      setRummyType(rummy)
      setGameType(game)
      setMaxPlayer(player)
      setTablePoint(point)
      if (feeTable) {
        setTableFee(feeTable)
      }
    } else if (maintainance == true) {
      setShowWarning(true)
    }
  }

  function playCashRummy() {
    if (tableFee) {
      dispatch(joinTable(rummyType, gameType, maxPlayer, tablePoint, token, tableFee))
      setTableFee(null)
    } else {
      dispatch(joinTable(rummyType, gameType, maxPlayer, tablePoint, token))
    }
    setPlayModal(false)
    setRummyType('')
    setGameType('')
    setMaxPlayer('')
    setTablePoint('')
    setShowConfirmPlay(false)
    setTableId('')
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
    setSelectedOption(null)
  }

  console.log("tableData", tableData)
  const twoPlayerOptions = tableData?.length > 0 && tableData
    .filter((item) => item?.nMaxPlayer === 2)
    .map((item) => ({
      label: `${item.nTablePoint}`,
      value: item?.nTablePoint,
      fullData: item
    }))

  const [selectedOption, setSelectedOption] = useState(twoPlayerOptions[0])

  const sixPlayerOptions = tableData?.length > 0 && tableData
    .filter((item) => item.nMaxPlayer === 6)
    .map((item) => ({
      label: `${item.nTablePoint}`,
      value: item.nTablePoint,
      fullData: item
    }))

  const handleSelectChange = (selected) => {
    setSelectedOption(selected)
  }
  return (
    <div>
      {loading && <Loading />}
      {/* <div className="table-cnt table-cnt-box"> */}
      <div className='table-cnt p-0'>
        <Nav tabs className=''>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1')
                setActiveProfiletab('1')
              }}
            >
              2 Players
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2')
                setActiveProfiletab('2')
              }}
            >
              6 Players
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId='1'>
            <div className='d-flex justify-content-center p-3 flex-column'>
              <div className='cust-dropdown-box'>
                <Select
                  options={twoPlayerOptions || []}
                  onChange={handleSelectChange}
                  value={selectedOption}
                  defaultValue={twoPlayerOptions[0]}
                  className='dropdown-kyc-box'
                  placeholder={'Select a Point Value'}
                />
              </div>
              <button
                type='button'
                onClick={() =>
                  GamePlayModal(
                    selectedOption.fullData.eRummyType,
                    selectedOption.fullData.eGameType,
                    selectedOption.fullData.nMaxPlayer,
                    selectedOption.fullData.nTablePoint
                  )
                }
                className='common-outline-btn play-btn'
              >
                <span className='bg-layer'>
                  <span className='bg-layer-inner'></span>
                </span>
                <span className='main-layer'>Play</span>
              </button>

            </div>
          </TabPane>
          <TabPane tabId='2'>
            <div className='d-flex justify-content-center p-3 flex-column'>
              <div className='cust-dropdown-box'>
                <Select
                  options={sixPlayerOptions || []}
                  onChange={handleSelectChange}
                  defaultValue={sixPlayerOptions[0]}
                  value={selectedOption}
                  className='dropdown-kyc-box'
                  placeholder={'Select a Point Value'}
                />
              </div>
              <button
                type='button'
                onClick={() =>
                  GamePlayModal(
                    selectedOption.fullData.eRummyType,
                    selectedOption.fullData.eGameType,
                    selectedOption.fullData.nMaxPlayer,
                    selectedOption.fullData.nTablePoint
                  )
                }
                className='common-outline-btn play-btn'
              >
                <span className='bg-layer'>
                  <span className='bg-layer-inner'></span>
                </span>
                <span className='main-layer'>Play {selectedOption?.value}</span>
              </button>
            </div>
          </TabPane>
        </TabContent>
      </div>
      <Modal
        isOpen={playModal}
        // toggle={logout}
        className='logout-modal modal-dialog-centered start-game-popup'
      >
        <div className='modal-body '>
          <div className='txt-msg-box'>
            <p>Are you sure you want to play this game?</p>
          </div>
          <div className='new-create-box text-center'>
            <button type='button' className='btn theme-orange-btn' onClick={playCashRummy}>
              <span>Yes</span>
            </button>
            <button type='button' className='btn theme-orange-btn' onClick={GamePlayModal}>
              <span>No</span>
            </button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={showWarning} className='logout-modal modal-dialog-centered'>
        <div className='modal-body logoutbackground maintainace-bg '>
          <div className='txt-msg-box'>
            <p>
              <strong>The game is currently under maintenance.</strong> <br /> <br />
              It will be back in {hour > 0 && ('0' + hour).slice(-2)}
              {hour > 0 && ':'}
              {('0' + min).slice(-2)}:{('0' + sec).slice(-2)}.{/* {endMaintainanace}. */}
            </p>
          </div>
          <div className='new-create-box text-center'>
            <button type='button' className='btn theme-orange-btn' onClick={() => setShowWarning(false)}>
              <span>okay</span>
            </button>
          </div>
        </div>
      </Modal>

      {/* <div className={showConfirmPlay ? "edit-auth-dtl active" : "edit-auth-dtl"}>
                <div>
                    <p><strong>Are you sure you want to play this game?</strong></p>
                    <div className="d-flex side-btn justify-content-center">
                        <input type="button" className="common-btn yellow-btn small-btn" value="Play" onClick={playCashRummy} />
                        <input type="button" className="common-btn yellow-btn small-btn" value="Cancel" onClick={() => setShowConfirmPlay(false)} />
                    </div>
                </div>
            </div> */}
      {/* </div> */}
    </div>
  )
}

export default connect()(Cash)
