/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Collapse, FormGroup, Label, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Button, Modal, ModalBody, NavLink, Row, Col, Container, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OldSocialLogin as SocialLogin } from 'react-social-login'
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script';
import { Login, Registration, VerifyOtp, SendOtp, forgotPassword, resetPasswordVerify, socialLogin, verifyEmailRequest, referralVerify } from "../../actions/auth";
import { verifyEmail, verifyMobileNumber, verifyLength, verifyPassword, verifyUsername, isNumber } from "../../helperNew";
import Loading from '../../components/Loading'
import bannerSliderImg from '../../assets/images/img/slider-img.png';
import raniimg from '../../assets/images/rani-img.png';

import CloseIcon from "../../assets/images/icons/Close.png";
import constants from '../../constants';
import Homepage from './Homepage';
import Header from './Header';
import Footer from './Footer';
// React slick files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from 'react-toastify';
import GooglePlay from '../../assets/images/icons/google-play.png';
import AppleStore from '../../assets/images/icons/apps-store.png';


function AuthHeader() {
    const [userName, setUserName] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [active, setActive] = useState(2);
    const [userEmail, setUserEmail] = useState("");
    const [userPass, setUserPass] = useState("");
    const [userMobile, setUserMobile] = useState("");
    const [userGender, setUserGender] = useState("male");
    const [userAgentId, setUserAgentId] = useState("");
    const [loginUser, setLoginUser] = useState("");
    const [loginPass, setLoginPass] = useState("");
    const [otp, setOtp] = useState("");
    const [errLoginUser, setErrLoginUser] = useState("");
    const [userErrName, setUserErrName] = useState("");
    const [userErrNumber, setUserErrNumber] = useState("");
    const [userErrPassword, setUserErrPassword] = useState("");
    const [userErrEmail, setUserErrEmail] = useState("");
    const [emailMobFP, setEmailMobFP] = useState('');
    const [mobFP, setMobFP] = useState();
    const [emailFP, setEmailFP] = useState();
    const [resetPassword, setResetPassword] = useState('');
    const [newPasswordInputShow, setNewPasswordInputShow] = useState(false);
    const [forgotPasswordId, setForgotPasswordId] = useState();
    const [userEmailDisable, setUserEmailDisable] = useState(false);
    const [socialAccessToken, setSocialAccessToken] = useState('');
    const [verificationId, setVerificationId] = useState('');
    const [loading, setLoading] = useState(false);
    const [closeModalConfirm, setCloseModalConfirm] = useState(false);
    const confirmCloseToggle = () => setCloseModalConfirm(!closeModalConfirm)


    const [IsSendOtp, setIsSendOtp] = useState(false);
    const [IsRegister, setIsRegister] = useState(false);
    const [isPassShaw, setIsPassShaw] = useState(false);
    const [isPassShaw2, setIsPassShaw2] = useState(false);
    const [isPassShaw3, setIsPassShaw3] = useState(false);
    const [sendedOtp, setSendedOtp] = useState(false);
    const [durationTime, setDurationTime] = useState("");
    const [FPModal, setFPModal] = useState(false);
    const [FPStatus, setFPStatus] = useState(false);

    const dispatch = useDispatch()
    function ForgotPass() {
        setFPModal(!FPModal)
        if (FPModal === false) {
            setEmailFP('')
            setMobFP('')
            setEmailMobFP('')
        }
    }
    const resMessage = useSelector((state) => state.auth.resMessage);
    const resStatus = useSelector((state) => state.auth.resStatus);
    const verificationToken = useSelector((state) => state.auth.verificationToken);
    const forgotPasswordStatus = useSelector(state => state.auth.resStatusFP)
    const forgotPasswordVerificationId = useSelector(state => state.auth.forgotPasswordVerificationId)
    const resetPasswordStatus = useSelector(state => state.auth.resStatusRP)
    const isSended = useSelector((state) => state.auth.isSended);
    const verificationIds = useSelector((state) => state.auth);
    const socialRegistrationData = useSelector(state => state.auth.socialRegistrationData)
    const accessToken = useSelector(state => state.auth.accessToken)
    const referralId = useSelector(state => state.auth.referralId)
    const ReferralStatus = useSelector(state => state.auth.resStatusRef)
    const ReferralMsg = useSelector(state => state.auth.resMessageRef)


    const socialGoogleStatus = useSelector(state => state.auth.resStatusSocial)

    let inputScrollRef = useRef();


    const previousProps = useRef({ resMessage, verificationToken, isSended, resetPasswordStatus, ReferralMsg, ReferralStatus, referralId }).current;

    useEffect(() => {
        document.title = 'Rummy246'
        const saveEmail = localStorage.getItem("userEmail")
        if (saveEmail) {
            setLoginUser(saveEmail)
        }
        if (localStorage.getItem('RegistrationOTP')) {
            setIsSendOtp(true)
            setNewPasswordInputShow(false)
            const data = JSON.parse(localStorage.getItem('RegistrationOTP'))
            setVerificationId(data?.verificationId)
            setUserMobile(data?.registrationPhone)
            setLoginUser(data?.loginPhone)
        }
        if (localStorage.getItem('ForgotPasswordOTP')) {
            setIsSendOtp(true)
            setNewPasswordInputShow(true)
            const data = JSON.parse(localStorage.getItem('ForgotPasswordOTP'))
            setForgotPasswordId(data?.verificationId)
            setMobFP(data?.Mobile)
            data?.Email?.length && setEmailFP(data?.Email)
            data?.OTPTimer === undefined && setDurationTime(0)
        }
    }, [])

    useEffect(() => {
        if (previousProps.ReferralMsg !== ReferralMsg) {
            if (ReferralMsg !== 'success.' && ReferralMsg !== undefined && ReferralMsg?.length) {
                toast(
                    <div>
                        <div>
                            {ReferralMsg}
                        </div>
                    </div>
                );
            }
        }
        return () => {
            previousProps.ReferralMsg = ReferralMsg
        }
    }, [ReferralMsg])


    useEffect(() => {
        if (ReferralStatus == true) {
            dispatch(Registration(userEmail, userPass, userMobile, userName, userGender, referralId, socialAccessToken));
            setLoading(false)
        } else if (ReferralStatus == false) {
            setLoading(false)
        }
    }, [ReferralStatus])

    console.log(verificationIds);



    useEffect(() => {
        if (IsSendOtp === true) {
            if (localStorage.getItem('OTPTimer') === null) {
                setDurationTime(180)
            } else {
                let timer = localStorage.getItem('OTPTimer')
                if (timer > 0) {
                    setInterval(() => {
                        if (timer >= 0) {
                            setDurationTime(timer);
                            timer = timer - 1;
                        }
                    }, 1000);
                }

            }
            localStorage.setItem('OTPTimer', durationTime)
        }
    }, [IsSendOtp])

    console.log(resMessage);


    useEffect(() => {
        if (previousProps.resMessage !== resMessage) {
            if (resMessage?.length) {
                setFPStatus(false)
                setLoading(false)
                if (resMessage === 'Mobile number not verified.') {
                    dispatch(SendOtp(userMobile || loginUser, ""));
                    setIsSendOtp(true);
                    setSendedOtp(true);
                    const sToken = localStorage.getItem('verificationCode')
                    const RegistrationData = { 'verificationId': sToken, 'registrationPhone': userMobile, 'loginPhone': loginUser }
                    console.log(RegistrationData);

                    localStorage.setItem('RegistrationOTP', JSON.stringify(RegistrationData));
                }

                toast(
                    <div>
                        <div>{resMessage}</div>
                    </div>
                    , { autoClose: 3000 });
            }
        }
        return () => {
            previousProps.resMessage = resMessage
        }
    }, [resMessage])

    useEffect(() => {
        if (active === 2 && (userName?.length || userEmail?.length || userMobile?.length || userPass?.length)) {
            window.onbeforeunload = function () {
                return true;
            }
        }
        return () => {
            window.onbeforeunload = null;
        };
    }, [active, userName, userEmail, userMobile, userPass]);


    useEffect(() => {
        if (sendedOtp === true) {
            changeClock();
            setTimeout(() => {
                setSendedOtp(false);
            }, 60000);
        }
    }, [sendedOtp]);

    useEffect(() => {
        if (previousProps.isSended !== isSended) {
            if (isSended) {
                setForgotPasswordId(forgotPasswordVerificationId)
                setSendedOtp(true);
            }
        }
        return () => {
            previousProps.isSended = isSended;
        };
    }, [isSended]);

    useEffect(() => {
        if (previousProps.verificationToken !== verificationToken) {
            if (verificationToken) {
                setLoading(false)
                setIsSendOtp(true);
                setSendedOtp(true);
                const RegistrationData = { 'verificationId': verificationToken, 'registrationPhone': userMobile, 'loginPhone': loginUser }
                localStorage.setItem('RegistrationOTP', JSON.stringify(RegistrationData));
            }
        }
        return () => {
            previousProps.verificationToken = verificationToken;
        };
    }, [verificationToken]);

    useEffect(() => {
        if (forgotPasswordStatus == true) {
            setFPStatus(true)
            setIsSendOtp(true)
            setSendedOtp(true);
            setNewPasswordInputShow(true)
            setForgotPasswordId(forgotPasswordVerificationId)
            let dataObject = { 'verificationId': forgotPasswordVerificationId, 'Mobile': mobFP, 'Email': emailFP }
            localStorage.setItem('ForgotPasswordOTP', JSON.stringify(dataObject));
        } else if (forgotPasswordStatus == false) {
            setFPStatus(false)
            setEmailMobFP('')
        }
    }, [forgotPasswordStatus])


    useEffect(() => {
        if (previousProps.resetPasswordStatus !== resetPasswordStatus) {
            if (resetPasswordStatus === true) {
                localStorage.removeItem('ForgotPasswordOTP');
                localStorage.removeItem('OTPTimer');
                setNewPasswordInputShow(false)
                setIsSendOtp(false)
                setSendedOtp(false)
                setFPModal(false)
                setEmailMobFP('')
                setEmailFP('')
                setMobFP('')
                setForgotPasswordId('')
                setOtp('')
                setResetPassword('')
            } else if (resetPasswordStatus === false) {
                setFPStatus(false)
                setOtp('')
                // setResetPassword('')
            }
        }
        return () => {
            previousProps.resetPasswordStatus = resetPasswordStatus
        }
    }, [resetPasswordStatus])


    function handleChange(event, type) {
        switch (type) {
            case "LoginUserName":
                if (
                    verifyMobileNumber(event.target.value) ||
                    verifyEmail(event.target.value)
                ) {
                    setErrLoginUser("");
                } else if (!verifyLength(event.target.value, 1)) {
                    setErrLoginUser("Email / Mobile is Required");
                } else {
                    setErrLoginUser("Enter username between 2 to 10 characters");
                }
                setLoginUser(event.target.value);
                break;
            case "LoginPassword":
                setLoginPass(event.target.value);
                break;
            case "SignEmail":
                if (
                    verifyLength(event.target.value, 1) &&
                    verifyEmail(event.target.value)
                ) {
                    setUserErrEmail("");
                } else {
                    setUserErrEmail("Enter a valid Email id");
                }
                setUserEmail(event.target.value.toLowerCase());
                break;
            case "SignName":
                if (verifyUsername(event, 0, 10)) {
                    setUserErrName("");
                    setUserName(event);
                } else {
                    setUserErrName("Name must be minimum 3 character.");
                }
                break;
            case "SignPhoneNumber":
                if (verifyMobileNumber(event.target.value)) {
                    setUserErrNumber("");
                } else {
                    setUserErrNumber("Mobile number should be 10 digit.");
                }
                event.target.value?.length <= 10 && setUserMobile(event.target.value);
                break;
            case "SignPassword":
                if (verifyPassword(event.target.value)) {
                    setUserErrPassword("");
                } else {
                    setUserErrPassword(
                        "Password need to be 8 to 16 characters");
                }
                setUserPass(event.target.value);
                break;
            case "SignGender":
                setUserGender(event.target.value);
                break;
            case "SignAgentId":
                if (verifyUsername(event.target.value, 0, 9)) {
                    setUserAgentId(event.target.value);
                }
                break;
            case "ForgotPassword":
                if ((verifyLength(event.target.value, 1) && verifyEmail(event.target.value))) {
                    setEmailFP(event.target.value)
                    setMobFP('')
                } else if ((verifyLength(event.target.value, 1) && verifyMobileNumber(event.target.value))) {
                    setMobFP(event.target.value)
                    setEmailFP('')
                }
                setEmailMobFP(event.target.value)
                break
            case "ResetPassword":
                setResetPassword(event.target.value);
                break

            default:
                break;
        }

    }


    function handleLogin(e) {
        e.preventDefault();
        if (loginUser.length > 0 && loginPass.length > 7) {
            dispatch(Login(loginUser, loginPass));

        } else {
            if (loginUser.length <= 0) {
                toast(
                    <div>
                        <div>Enter a valid Email / Mobile</div>
                    </div>
                    , { autoClose: 3000 });
            } else if (loginPass.length < 7) {
                toast(
                    <div>
                        <div>Password need to be 8 to 16 characters</div>
                    </div>
                    , { autoClose: 3000 });
            }

        }
        // if (verifyPassword(loginPass) && (verifyMobileNumber(loginUser) || verifyEmail(loginUser))) {
        //     setLoading(true)
        //     localStorage.setItem("userEmail", loginUser);
        // } 
        // else {
        //     if (!verifyMobileNumber(loginUser) && !verifyEmail(loginUser)) {
        //         toast(
        //             <div>
        //                 <div>Enter Email or Mobile No.</div>
        //             </div>
        //             , { autoClose: 3000 });
        //     }
        //     else if (!verifyPassword(loginPass)) {
        //         toast(
        //             <div>
        //                 <div>Invalid credential</div>
        //             </div>
        //             , { autoClose: 3000 });
        //     }
        // }
    }

    function handlePopupRegistraion() {
        if (
            verifyPassword(userPass) &&
            verifyMobileNumber(userMobile) &&
            verifyEmail(userEmail) &&
            verifyUsername(userName, 2, 10)
        ) {
            setIsRegister(true);
        } else {
            if (!verifyUsername(userName, 2, 10)) {
                toast(
                    <div>
                        <div>Enter username between 2 to 10 characters</div>
                    </div>
                    , { autoClose: 3000 });
            }
            else if (!verifyPassword(userPass)) {
                toast(
                    <div>
                        <div>Password need to be 8 to 16 characters</div>
                    </div>
                    , { autoClose: 3000 });
            }
            else if (!verifyMobileNumber(userMobile)) {
                toast(
                    <div>
                        <div>Enter a valid Mobile number</div>
                    </div>
                    , { autoClose: 3000 });
            }
            else if (!verifyEmail(userEmail)) {
                toast(
                    <div>
                        <div>Enter a valid Email id</div>
                    </div>
                    , { autoClose: 3000 });
            }
        }
    }

    function handleRegistration() {
        if (verifyPassword(userPass) && verifyMobileNumber(userMobile) && verifyEmail(userEmail) && verifyUsername(userName, 2, 10)) {
            if (socialAccessToken?.length) {
                setLoading(true)
                if (userAgentId?.length) {
                    dispatch(referralVerify(userAgentId))
                } else {
                    dispatch(Registration(userEmail, userPass, userMobile, userName, userGender, userAgentId, socialAccessToken));
                }
                setSocialAccessToken('')
            } else {
                setLoading(true)
                if (userAgentId?.length) {
                    dispatch(referralVerify(userAgentId))
                } else {
                    dispatch(Registration(userEmail, userPass, userMobile, userName, userGender, userAgentId, null));
                    setSocialAccessToken('')
                }
            }
            setNewPasswordInputShow(false)
            setIsRegister(false);
        }
    }

    function handleVerifyOtp(e) {
        e.preventDefault()
        if (newPasswordInputShow) {
            if (!verifyPassword(resetPassword)) {
                toast(
                    <div>
                        <div>Password need to be 8 to 16 characters</div>
                    </div>
                    , { autoClose: 3000 });
            } else if (verifyLength(otp, 4)) {
                setLoading(true)
                dispatch(resetPasswordVerify(otp, resetPassword, forgotPasswordId))
            }
        } else {
            if (verifyLength(otp, 4)) {
                setLoading(true)
                if (localStorage.getItem('verificationCode')) {
                    dispatch(VerifyOtp(localStorage.getItem('verificationCode') || verificationId, otp, userMobile || loginUser));

                } else {
                    dispatch(VerifyOtp(verificationToken || verificationId, otp, userMobile || loginUser));
                }
            }
        }
    }

    const changeClock = () => {
        let duration = 180;
        if (duration > 0) {
            setInterval(() => {
                if (duration >= 0) {
                    setDurationTime(duration);
                    duration = duration - 1;
                }
            }, 1000);
        }
    };

    useEffect(() => {
        if (IsSendOtp === true) {
            localStorage.setItem('OTPTimer', durationTime)
        }
    }, [IsSendOtp, durationTime])

    const InputonWheel = () => {
        inputScrollRef.current.blur();
        setTimeout(() => inputScrollRef.current.focus(), 100);
    };

    function handleForgotPass() {
        if (verifyEmail(emailFP) || verifyMobileNumber(mobFP)) {
            setLoading(true)
            dispatch(forgotPassword(emailFP, mobFP))
            // setEmailFP('')
            // setMobFP('')
        } else {
            if (!verifyEmail(emailFP) && !verifyMobileNumber(mobFP)) {
                toast(
                    <div>
                        <div>Enter a valid Email id or Mobile number</div>
                    </div>
                    , { autoClose: 3000 });
            }

        }
    }

    useEffect(() => {
        if (socialRegistrationData) {
            setActive(2)
            setUserEmail(socialRegistrationData?.fields?.sEmail)
            setUserEmailDisable(true)
        }
    }, [socialRegistrationData])

    useEffect(() => {
        if (accessToken) {
            setSocialAccessToken(accessToken)
        }
    }, [accessToken])


    const responseFromGoogle = (response) => {
        const accessToken = response?.credential
        if (accessToken) {
            setLoading(true)
            dispatch(socialLogin('Google', accessToken))
        }
    }

    function facebookLogin(user) {
        if (user?._token?.accessToken) {
            const accessToken = user?._token?.accessToken
            const userId = user?._profile?.id
            if (accessToken) {
                setLoading(true)
                dispatch(socialLogin('Facebook', accessToken, userId))
            }
        }
    }

    const responseGoogle = (response) => {
        const accessToken = response?.tokenObj?.id_token
        if (accessToken) {
            dispatch(socialLogin('Google', accessToken))
        }
    }
    useEffect(() => {
        function start() {
            gapi.client.init({
                client_id: constants.GOOGLE_CLIENT_ID_RR,
                scope: 'https://www.googleapis.com/auth/drive.metadata.readonly'
            })
        }
        gapi.load('client:auth2', start);
    });
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    function otpModalToggle() {
        if (IsSendOtp === true) {
            setCloseModalConfirm(true)
        }
    }

    function handleCloseOtpModal() {
        setIsSendOtp(false);
        setCloseModalConfirm(false);
        setEmailMobFP('');
        setEmailFP('');
        setMobFP('');
        setOtp('');
        setResetPassword('');
        setUserMobile('');
        setLoginUser('');
        localStorage.removeItem('RegistrationOTP');
        localStorage.removeItem('ForgotPasswordOTP');
        localStorage.removeItem('OTPTimer');
    }

    function handleResendOtp() {

        if (newPasswordInputShow) {
            if (mobFP) {
                setLoading(true)
                dispatch(SendOtp(mobFP))
            }
            else if (emailFP) {
                setLoading(true)
                dispatch(verifyEmailRequest(emailFP))
            }
        } else {
            if (durationTime === 0 && (userMobile || loginUser)) {
                setLoading(true)
                dispatch(SendOtp(userMobile || loginUser, ""));
            }
        }
    }

    return (
        <div>
            {loading && <Loading />}
            <Header />
            <div className="site-content">
                <div className="banner-area">
                    <Container>
                        <Row className='align-items-center login-page'>

                            <Col md="5" >
                                <div className="user-credential-box">
                                    <div
                                        id="sign-up"
                                        className="signup-screen _mPS2id-t mPS2id-target mPS2id-target-first mPS2id-target-last"
                                    >
                                        <div className="login-screen">
                                            <ul className="nav nav-tabs">
                                                <li className={active === 1 && "active"} onClick={() => setActive(1)}>
                                                    <a className="active login-tab login-register" data-toggle="tab">
                                                        log in
                                                    </a>
                                                </li>
                                                <li className={active === 2 && "active"} onClick={() => setActive(2)} >
                                                    <a className="register-tab login-register" data-toggle="tab">
                                                        register
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content">
                                                {active === 1 && (
                                                    <Fragment>
                                                        <div className="tab-pane active">
                                                            <div className="sign-detail">
                                                                <form onSubmit={handleLogin} className='login-form'>
                                                                    <div>
                                                                        <div className="form-group">
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Mobile No/ Email ID"
                                                                                style={{ "margin": "15px 0" }}
                                                                                value={loginUser}
                                                                                onChange={(e) => {
                                                                                    handleChange(e, "LoginUserName");
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group d-flex align-items-center">
                                                                            <Input
                                                                                type={`${isPassShaw ? "text" : "password"
                                                                                    }`}
                                                                                maxLength={15}
                                                                                className="form-control"
                                                                                id="exampleInputPassword1"
                                                                                placeholder="Enter Your Password"
                                                                                value={loginPass}
                                                                                onChange={(e) => {
                                                                                    handleChange(e, "LoginPassword");
                                                                                }}
                                                                            />
                                                                            {/* <i className="fa fa-eye" aria-hidden="true"></i> */}
                                                                            <i
                                                                                className={`fa ${isPassShaw ? "fa-eye-slash" : "fa-eye"
                                                                                    }`}
                                                                                aria-hidden="true"
                                                                                onClick={() => setIsPassShaw(!isPassShaw)}
                                                                            ></i>
                                                                        </div>
                                                                        <p className="forgot-text text-right">
                                                                            <a
                                                                                onClick={ForgotPass}
                                                                            // data-toggle="modal"
                                                                            // data-target="#ForgetPasswordWrap"
                                                                            >
                                                                                Forget password?
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                    <Button
                                                                        type="submit"
                                                                        className="btn login-btn"
                                                                    // onClick={handleLogin}
                                                                    >
                                                                        Login
                                                                    </Button>
                                                                    {/* <p className="or-text text-center">
                                                                        <span>or</span>
                                                                    </p> */}
                                                                    {/* <div className="facebook-btn">
                                                                        <a
                                                                            className="btn fb-btn"
                                                                        >
                                                                            <SocialLogin
                                                                                className='inner-social-btn'
                                                                                provider='facebook'
                                                                                appId={constants.FACEBOOK_APP_ID_RR}
                                                                                callback={facebookLogin}
                                                                            >
                                                                                <Button type="button">  </Button>
                                                                            </SocialLogin>

                                                                            Login with Facebook
                                                                        </a>
                                                                    </div> */}
                                                                    {/* <div className="goggle-btn">
                                                                        <a className="btn g-btn" >
                                                                            <GoogleLogin
                                                                                className='inner-social-btn'
                                                                                clientId={constants.GOOGLE_CLIENT_ID_RR}
                                                                                buttonText="Sign In"
                                                                                onSuccess={responseGoogle}
                                                                                onFailure={responseGoogle}
                                                                                cookiePolicy={'single_host_origin'} />
                                                                            Google
                                                                        </a>
                                                                    </div> */}
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )}
                                                {active === 2 && (
                                                    <Fragment>
                                                        <div className="tab-pane active">
                                                            <div className="sign-detail ragisters-detail register-detail"  >
                                                                <form>
                                                                    <div className="form-group" >
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="text"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Username"
                                                                            value={userName}
                                                                            onChange={(e) => {
                                                                                handleChange(e.target.value.replace(/\s/g, ''), "SignName");
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group" >
                                                                        <Input
                                                                            type="email"
                                                                            disabled={userEmailDisable}
                                                                            className="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter your email"
                                                                            value={userEmail}
                                                                            onChange={(e) => {
                                                                                handleChange(e, "SignEmail");
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control number"
                                                                            id="exampleInputPassword1"
                                                                            ref={inputScrollRef} onWheel={InputonWheel}
                                                                            placeholder="Enter your mobile number"
                                                                            value={userMobile}
                                                                            onChange={(e) => {
                                                                                handleChange(e, "SignPhoneNumber");
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group d-flex align-items-center">
                                                                        <Input
                                                                            type={`${isPassShaw2 ? "text" : "password"
                                                                                }`}
                                                                            className="form-control"
                                                                            maxLength={15}
                                                                            id="exampleInputPassword1"
                                                                            placeholder="Password (atleast 8 chars)"
                                                                            value={userPass}
                                                                            autoComplete="new-password"
                                                                            onChange={(e) => {
                                                                                handleChange(e, "SignPassword");
                                                                            }}
                                                                        />
                                                                        {/* <i className="fa fa-eye" aria-hidden="true"></i> */}
                                                                        <i
                                                                            className={`fa ${isPassShaw2 ? "fa-eye-slash" : "fa-eye"
                                                                                }`}
                                                                            aria-hidden="true"
                                                                            onClick={() =>
                                                                                setIsPassShaw2(!isPassShaw2)
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="object-select d-flex align-items-center justify-content-between">
                                                                            {/* <FormGroup tag="fieldset">
                                                                                <FormGroup check>
                                                                                    <Input
                                                                                        type="radio"
                                                                                        name="rtest"
                                                                                        id="male"
                                                                                        value="male"
                                                                                    />
                                                                                    {' '}
                                                                                    <Label check>
                                                                                        Option one is this and that—be sure to include why it&#39;s great
                                                                                    </Label>
                                                                            <Label check
                                                                                htmlFor="male"
                                                                                title="Unchecked state"
                                                                            >
                                                                                Male
                                                                            </Label>
                                                                        </FormGroup>
                                                                        <FormGroup check>
                                                                            <Input
                                                                                type="radio"
                                                                                name="rtest"
                                                                                id="female"
                                                                                value="female"
                                                                            />
                                                                            {' '}
                                                                            <Label check>
                                                                                Female
                                                                            </Label>
                                                                        </FormGroup>

                                                                    </FormGroup> */}

                                                                            {/* ol radio below */}
                                                                            <div className='gender-grp'>
                                                                                <div className="form-group d-flex align-items-center">
                                                                                    {/* <Input
                                                                                    type="radio"
                                                                                    name="rtest"
                                                                                    id="male"
                                                                                    value="male"
                                                                                    checked={userGender === "male"}
                                                                                    onChange={(e) => {
                                                                                        handleChange(e, "SignGender");
                                                                                    }}
                                                                                />
                                                                                <span></span>
                                                                                <label
                                                                                    htmlFor="male"
                                                                                    title="Unchecked state"
                                                                                >
                                                                                    Male
                                                                                </label> */}
                                                                                    <FormGroup check>
                                                                                        <Input
                                                                                            type="radio"
                                                                                            name="rtest"
                                                                                            id="male"
                                                                                            value="male"
                                                                                            checked={userGender === "male"}
                                                                                            onChange={(e) => {
                                                                                                handleChange(e, "SignGender");
                                                                                            }}
                                                                                        />
                                                                                        {' '}
                                                                                        <Label check htmlFor="male">
                                                                                            Male
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                </div>
                                                                                <div className="form-group d-flex align-items-center female-btn">
                                                                                    <Input
                                                                                        type="radio"
                                                                                        name="rtest"
                                                                                        id="female"
                                                                                        value="female"
                                                                                        data-jcf='{"wrapNative": true}'
                                                                                        className=""
                                                                                        checked={userGender === "female"}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e, "SignGender");
                                                                                        }}
                                                                                    />
                                                                                    <span></span>
                                                                                    <label
                                                                                        htmlFor="female"
                                                                                        title="Checked state"
                                                                                        className="jcf-label-active"
                                                                                    >
                                                                                        Female
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group agent-id">
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="exampleInputPassword1"
                                                                                    placeholder="Referral code"
                                                                                    value={userAgentId}
                                                                                    onChange={(e) => {
                                                                                        handleChange(e, "SignAgentId");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Button
                                                                        type="button"
                                                                        className="btn  login-btn regester-btn"
                                                                        onClick={handlePopupRegistraion}
                                                                    >
                                                                        Register
                                                                    </Button>
                                                                    {/* <p className="or-text text-center">
                                                                        <span>or</span>
                                                                    </p> */}
                                                                    {/* <div className="facebook-btn">
                                                                        <a
                                                                            className="btn fb-btn"
                                                                        >
                                                                            <SocialLogin
                                                                                className='inner-social-btn'
                                                                                provider='facebook'
                                                                                appId={constants.FACEBOOK_APP_ID_RR}
                                                                                callback={facebookLogin}
                                                                            >
                                                                                <Button type="button">  </Button>
                                                                            </SocialLogin>

                                                                            Login with Facebook
                                                                        </a>
                                                                    </div>
                                                                    <div className="goggle-btn">
                                                                        <a
                                                                            className="btn g-btn"
                                                                        >
                                                                            <GoogleLogin
                                                                                className='inner-social-btn'
                                                                                clientId={constants.GOOGLE_CLIENT_ID}
                                                                                buttonText="Sign In"
                                                                                onSuccess={responseGoogle}
                                                                                onFailure={responseGoogle}
                                                                                cookiePolicy={'single_host_origin'} />

                                                                            Google
                                                                        </a>
                                                                    </div> */}
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Container >
                </div >
            </div >

            <Modal isOpen={IsSendOtp} toggle={otpModalToggle} className="modal-dialog-centered modal-verify-otp-wrap">
                <div className="modal-header" toggle={otpModalToggle}>



                </div>


                <ModalBody>
                    <div className='coomon-popup-sec'>
                        <h3 className="modal-title text-center" id="exampleModalLongTitle">
                            Verify Otp
                        </h3>
                        {/* <Button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                            <img
                                src={CloseIcon}
                                alt="close"
                                onClick={otpModalToggle}
                            />
                        </span>
                    </Button> */}
                        <form onSubmit={(e) => handleVerifyOtp(e)}>
                            <div className="text-center">
                                <h4>
                                    {newPasswordInputShow ? mobFP ? `Enter the OTP from the SMS we just sent you` : `Enter the OTP from the email we just sent to you` : `Enter the OTP from the SMS we just sent you ${userMobile || loginUser}`}

                                </h4>
                            </div>
                            <div className="form-group sign-detail verify-box">
                                <Input type="text" autoComplete="new-password" maxLength="4" className="form-control number" id="" placeholder="Enter Otp" value={otp} onChange={(e) => {
                                    if (e.target.value.length <= 4 && (isNumber(e.target.value) || e.target.value === "")) {
                                        setOtp(e.target.value.replace(/[^\d]/g, ''))
                                    }
                                }} />
                                <div className={newPasswordInputShow ? "form-group d-flex align-items-center" : "d-none"}>
                                    <Input
                                        type={`${isPassShaw3 ? "text" : "password"}`}
                                        className="form-control"
                                        autoComplete="new-password"
                                        placeholder="Enter New Password"
                                        value={resetPassword}
                                        onChange={(e) => {
                                            handleChange(e, "ResetPassword");
                                        }}
                                    />
                                    <i
                                        className={`fa ${isPassShaw3 ? "fa-eye-slash" : "fa-eye"
                                            }`}
                                        aria-hidden="true"
                                        onClick={() => setIsPassShaw3(!isPassShaw3)}
                                    ></i>
                                </div>

                                <div className='resend-box d-flex justify-content-between'>
                                    <Button type='button'
                                        disabled={(durationTime > 0) ? true : false} className={durationTime > 0 ? "resend-otp-btn" : "resend-otp-btn active"} onClick={handleResendOtp}>
                                        Resend OTP
                                    </Button>
                                    {durationTime > 0
                                        ? (
                                            <Fragment>
                                                {durationTime <= 60 && (
                                                    <Fragment>
                                                        <p>
                                                            00:
                                                            {durationTime > 9 ? durationTime : `0${durationTime}`}
                                                        </p>
                                                    </Fragment>
                                                )}
                                                {durationTime <= 120 && durationTime > 60 && (
                                                    <Fragment>
                                                        <p>
                                                            01:
                                                            {durationTime - 60 > 9
                                                                ? durationTime - 60
                                                                : `0${durationTime - 60}`}
                                                        </p>
                                                    </Fragment>
                                                )}
                                                {durationTime <= 180 && durationTime > 120 && (
                                                    <Fragment>
                                                        <p>
                                                            02:
                                                            {durationTime - 120 > 9
                                                                ? durationTime - 120
                                                                : `0${durationTime - 120}`}
                                                        </p>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <p>00:00</p>
                                        )}
                                </div>
                            </div>

                            <div className="new-create-box text-center">
                                <Button
                                    type="submit"
                                    className="btn theme-orange-btn forget-pass-btn btn btn-secondary"
                                // onClick={handleVerifyOtp}
                                >
                                    <span>Submit</span>
                                </Button>
                            </div>
                        </form>
                    </div>

                </ModalBody>
            </Modal>

            <Modal
                isOpen={IsRegister}
                toggle={() => setIsRegister(!IsRegister)}
                className="modal-dialog-centered modal-verify-otp-wrap"
            >

                <ModalBody>
                    <div className='coomon-popup-sec'>
                        <div
                            className=""
                            toggle={() => setIsRegister(!IsRegister)}>
                            <h3 className="modal-title text-center" id="exampleModalLongTitle">
                                Register{" "}
                            </h3>
                            {/* <Button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <img
                                        src={CloseIcon}
                                        alt="close"
                                        onClick={() => setIsRegister(!IsRegister)}
                                    />
                                </span>
                            </Button> */}
                        </div>
                        <div className="text-center sign-detail ">
                            <h4>
                                Are You sure you want to use this
                                <br /> Mobile No: <span>{userMobile}</span> <br />Email Id: <span>{userEmail}</span> {" "}
                                <br /> For Registration?{" "}
                            </h4>
                            <div className="new-create-box text-center">
                                <Button
                                    type="button"
                                    className="btn theme-orange-btn"
                                    data-toggle="modal"
                                    data-target="#RegisterWrap"
                                    onClick={handleRegistration}
                                >
                                    <span>Submit</span>
                                </Button>
                                <Button
                                    type="button"
                                    className="btn ml-4 theme-orange-btn"
                                    data-toggle="modal"
                                    data-target="#RegisterWrap"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsRegister(false);
                                    }}
                                >
                                    <span>Cancel</span>
                                </Button>
                            </div>
                        </div>
                    </div>



                </ModalBody>
            </Modal>
            <Modal
                isOpen={FPModal}
                toggle={ForgotPass}
                className="joinTableModal ForgetPasswordWrap modal-dialog-centered"
            >
                <div className='coomon-popup-sec'>
                    <div className="">
                        <h3 className="modal-title text-center" id="">
                            Forgot Password
                        </h3>
                    </div>
                    <div className="modal-body ">
                        <div className="text-center">
                            <h4>
                                Please enter your registered Email or Mobile Number to Reset
                                your Password.
                            </h4>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleForgotPass();
                        }}>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Email or Mobile Number"
                                    value={emailMobFP}
                                    onChange={(e) => handleChange(e, 'ForgotPassword')}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleForgotPass();
                                        }
                                    }}
                                />
                            </div>
                            <div className="new-create-box text-center">
                                <Button type="submit" className="btn theme-orange-btn forget-pass-btn">
                                    <span>Submit</span>
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={closeModalConfirm}
                toggle={confirmCloseToggle}
                className="logout-modal modal-dialog-centered"
            >
                <div className='coomon-popup-sec confirmation-popup'>
                    <div className='model-body'>
                        <div className="txt-msg-box">
                            <p>Are you sure you want to discard the changes?</p>
                        </div>
                        <div className="new-create-box text-center">
                            <button
                                type="button"
                                className="btn theme-orange-btn"
                                onClick={handleCloseOtpModal}
                            >
                                <span>Yes</span>
                            </button>
                            <button
                                type="button"
                                className="btn theme-orange-btn close"
                                onClick={confirmCloseToggle}
                            >
                                <span>No</span>
                            </button>
                        </div>
                    </div>

                </div>
            </Modal>
            <Homepage />
            <Footer />
        </div >


    )
}

export default AuthHeader

