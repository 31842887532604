import React, { Fragment, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import PassbookDeposite from "./PassbookDeposite";
import PassbookAll from "./PassbookAll";
import PassbookWithdraw from "./PassbookWithdraw";


export default function Passbook() {
    const [activeTab, setActiveTab] = useState("1");

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    return (
        <Fragment>
            <div className="table-heading lobby-headeing">Passbook</div>
            <div className="account-cnt passbook-wrap">
                <div className="my-profile">
                    <div className="point-table">

                        <div className="table-cnt">
                            <Nav tabs className="justify-content-between d-flex">
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => { toggle("1"); }} >
                                        All
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === "2" })} onClick={() => { toggle("2"); }} >
                                        Withdraw
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === "3" })} onClick={() => { toggle("3"); }}>
                                        Deposit
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <PassbookAll tab={activeTab} />
                                </TabPane>
                                <TabPane tabId="2">
                                    <PassbookWithdraw tab={activeTab} />
                                    {/* <div className="passbook-withdraw-wrap">
                                        <div className="inner-withdraw-box text-center">
                                            <h4 className="big">Enter Amount to Withdraw </h4>
                                            <p className="txt-grey">Range (₹ 250 to 5000)</p>
                                            <p>Avilable withdrawl Balance : ₹1102.00</p>

                                            <input type="number" placeholder="₹ 1000" />

                                            <p className="txt-grey mb-40">Charges = ₹ 10</p>
                                            <div className="d-flex justify-content-center">
                                                <button type="button" className="yellow-btn">
                                                    <span className="main-layer">Bank Details</span>
                                                </button>
                                                <button type="button" className="yellow-btn">
                                                    <span className="main-layer">Withdraw</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}
                                </TabPane>
                                <TabPane tabId="3">
                                    <PassbookDeposite tab={activeTab} />
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}
