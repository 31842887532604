const stag = {
    // API_URL: 'https://rummyone-backend.lc.webdevprojects.cloud/api/v1',
    API_URL: 'http://13.202.87.84:3001/api/v1',
    // SOCKET_URL: 'http://rummyone-backend.lc.webdevprojects.cloud/',
    SOCKET_URL: 'http://13.202.87.84:3001/',
    GAME_URL: 'http://gorummy-game-stag.s3-website.ap-south-1.amazonaws.com',
    PAYMENT_KEY: 'rzp_test_1oBrjU3HkzdkS5',
}

const prod = {
    API_URL: 'https://api.rummy246.in/api/v1',
    SOCKET_URL: 'https://api.rummy246.in/',
    GAME_URL: "https://game.rummy246.in/",
    // GAME_URL: 'https://game.rummy24.com',
    PAYMENT_KEY: 'rzp_live_ZKhR7eRsYo7fcp',
}

const dev = {
    API_URL: 'http://192.168.11.55:3001/api/v1',
    SOCKET_URL: 'http://192.168.11.55:3001/',
    GAME_URL: "http://Rummy246-game-stag.s3-website.ap-south-1.amazonaws.com",
    // GAME_URL: 'https://game.rummy24.com',
    PAYMENT_KEY: 'rzp_live_ZKhR7eRsYo7fcp',
}


export default prod
