import React from 'react'
import Header from '../auth/Header';
import Footer from '../auth/Footer'

function RefundsCancellations() {
    return (
        <>
            <div>
                <Header />
                <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                    <div className="container">
                        <div className="about-us-content">
                            <h3 className="text-center page-title refund-title">Refunds & Cancellations</h3>
                            <div className='about-details-sec'>
                                <h4>Refund Policy</h4>
                                <p>Thank you for purchasing cash coins/cash from Rummy246.in, operated by Eseries Sports Private Ltd. Due to the nature of our business and the products we offer, we generally do not provide full refunds for purchases made on our website. We have implemented measures to keep you informed about your actions on our platform, reducing the likelihood of errors.</p>
                                <p>However, if within 2 working days, you are not satisfied with your purchase and have not used the product, you may be eligible for a refund. This will be evaluated on a case-by-case basis, and you may need to demonstrate that you misunderstood the actions taken during the purchase process. After a successful review, you may receive a full, partial, or no refund within 30 working days of your purchase.</p>
                                <p>Please note that after the 2 working day window, refunds will no longer be available. If you have any questions or wish to request a refund, please contact us at <a href="mailto:support@rummy246.in">support@rummy246.in</a>.</p>

                                <h4>Game Cancellation Settlement Policy</h4>
                                <p>If a Rummy Cash Game is canceled by Rummy246 due to technical issues or unfair play by some players, the following settlement policy will be applied:</p>

                                <h5>Points Rummy Cash Games</h5>
                                <p>In the event of cancellation, each player's table balance will be restored to the amount at the start of the canceled game and credited back to their account. This policy also applies to cases where collusion or cheating is detected, in addition to any further actions Rummy246 may take against those involved, as per our Terms and Conditions.</p>

                                <h4>Contact Support</h4>
                                <p>The Rummy246 support team is available 24/7 to ensure you have the best rummy experience. You can reach out to us from your registered email ID at <a href="mailto:support@rummy246.in">support@rummy246.in</a> with your concerns or issues, and our representative will respond promptly with a solution.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default RefundsCancellations